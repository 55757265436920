import { useContext, useEffect, useState } from "react";

// Context
import Report0Context from "../../../../../context/reports/report0/Report0Context";

// Libraries
import { toast } from 'react-toastify'
import AlertConfirmReport0 from "../modal/alertConfirm/Alert";

// Components and CSS
import './DragEmployees.css'

// Interfaces
import ModalData from "../../../../../interfaces/modal/ModalData";
import Report0Seeker from "../seeker/Seeker";
import { useHistory } from "react-router";

const DragEmployees: React.FC<ModalData> = (props) => {

    const history = useHistory()

    const { report, getReport, employees, services, getServices } = useContext(Report0Context)

    const [employeeCard, setEmployeeCard] = useState<HTMLDivElement>()
    const [reportData, setReportData] = useState<any>()
    const [columnIndex, setColumnIndex] = useState<number>(0)

    // Open Alert Confirm
    const [alertConfirm, setAlertConfirm] = useState({
        open: false,
        data: {}
    })

    useEffect(() => {
        if (localStorage.getItem('nextForm')) {
            getServices(parseInt(localStorage.getItem('nextForm')?.split(',')[1]!))
            generateArray()
        }
        if (localStorage.getItem('nextFormEdit')) {
            getReport(parseInt(localStorage.getItem('nextFormEdit')?.split(',')[3]!))
            getServices(parseInt(localStorage.getItem('nextFormEdit')?.split(',')[1]!))
            generateArray()
        }
    }, [])

    function comeBackForm() {
        if (!localStorage.getItem('nextFormEdit')) {
            localStorage.removeItem('nextForm')
        }
        if (localStorage.getItem('nextFormEdit')) {
            localStorage.setItem('nextFormEdit', localStorage.getItem('nextFormEdit')?.split(',')[0]! + ',' + localStorage.getItem('nextFormEdit')?.split(',')[1]! + ',' + 'edit' + ',' + localStorage.getItem('nextFormEdit')?.split(',')[3]! + ',' + 'false')
        }
        props.onClose()
    }

    function generateArray() {
        let i: any = []
        if (localStorage.getItem('nextForm')) {
            for (let index = 0; index < parseInt(localStorage.getItem('nextForm')?.split(',')[0]!); index++) {
                let data = { id: (index + 1), employees: [], service: '', createdAt: new Date().getTime() }
                i.push({ ...data })
            }
        }

        if (localStorage.getItem('nextFormEdit')) {
            for (let index = 0; index < parseInt(localStorage.getItem('nextFormEdit')?.split(',')[0]!); index++) {
                let data = { id: (index + 1), employees: [], service: '', createdAt: new Date().getTime() }
                i.push({ ...data })
            }
        }
        
        setReportData(i)
    }

    function generateColumns() {
        let content = []

        for (let index = 0; index < (localStorage.getItem('nextForm') ? parseInt(localStorage.getItem('nextForm')?.split(',')[0]!) : parseInt(localStorage.getItem('nextFormEdit')?.split(',')[0]!)); index++) {

            content.push(
                <div className="employees" key={index}>
                    <p>Cuadrilla {(index + 1)}</p>
                    <div className="column" onDragOver={(event) => dragOverColumns(event, index)} />
                    <div className="services-container">
                        <h5>Asignar servicio</h5>
                        <select className="services-select" onChange={(event) => selectService(event, index)} >
                            {
                                services.length ? (
                                    services.map((service, i) =>
                                        // <option key={i}>{localStorage.getItem('nextFormEdit') ? report[i]['service'] : service['name']}</option>
                                        <option key={i}>{service['name']}</option>
                                    )
                                ) : (<option disabled>Cargando servicios...</option>)
                            }
                        </select>
                    </div>
                </div>
            )
        }
        
        if (localStorage.getItem('nextFormEdit') && content.length === (localStorage.getItem('nextForm') ? parseInt(localStorage.getItem('nextForm')?.split(',')[0]!) : parseInt(localStorage.getItem('nextFormEdit')?.split(',')[0]!))) {
            setTimeout(() => {
                generateEditData()
            }, 200);
        }

        return content
    }

    
    function generateEditData () {
        const columns = document.querySelectorAll('.column')
        let arrCardEmpHTML: any = []
        let arrNames: String[] = []
        
        for (let index = 0; index < columns[0].children.length; index++) {
            const element: any = columns[0].children[index];
            arrNames.push(element.innerText)
            arrCardEmpHTML.push(element)
        }

        for (let index = 0; index < Object.keys(report).length; index++) {
            const employees: any = report[index];
            employees['employees'].forEach((employee: any) => {
                if (employees['employees'].indexOf(employee) > -1) {
                    const arrIndex = arrNames.indexOf(employee)
                    
                    if (arrCardEmpHTML[arrIndex] != undefined && columns.length > Object.keys(report).length) {
                        columns[employees['id']].append(arrCardEmpHTML[arrIndex])
                    }

                    arrCardEmpHTML.splice(arrIndex, 1)
                    arrNames.splice(arrIndex, 1)
                }
            });
        }
    }

    // I save the service selected
    function selectService(event: any, index: number) {
        let object = { id: (index + 1), employees: reportData[index]['employees'], service: event.target.value, createdAt: new Date().getTime() }
        reportData[index] = object
    }
    // Add class in employee card, save it in 
    // employee card variable and hide card
    function dragStart(event: any) {
        event.target.classList.add('dragged')
        setEmployeeCard(event.target)
        setTimeout(() => {
            event.target.style.display = 'none'
        }, 0);
    }
    // Remove the class of employee card and
    // if the user don't select a service the program
    // will select the first service
    function dragEnd(event: any) {
        if (!reportData[columnIndex]['service']) {
            reportData[columnIndex]['service'] = services[0]['name']
        }
        event.target.classList.remove('dragged')
    }
    // Add the employee in the column, show the employee asigned
    // and get the column id 
    function dragOverColumns(event: any, index: number) {
        event.preventDefault()
        event.target.append(employeeCard)
        setColumnIndex(index)
        setTimeout(() => {
            employeeCard!.style.display = 'block'
        }, 0);
    }

    function validate() {
        const columns = document.querySelectorAll('.column')
        for (let index = 1; index < columns.length; index++) {
            const column = columns[index];
            if (!column.childNodes.length) {
                return toast('Para continuar debe asignar personal en cada cuadrilla, verifique la cuadrilla ' + (index), {
                    type: 'error'
                })
            }
        }
        getEmployeesOfColumns()
    }

    function getEmployeesOfColumns() {
        const columns = document.querySelectorAll('.column')
        let idu = 0
        for (let index = 1; index < columns.length; index++) {
            const column = columns[index];
            reportData[idu]['employees'] = []

            for (let i = 0; i < column.childNodes.length; i++) {
                const element: any = column.childNodes[i];
                reportData[idu]['employees'].push(element.innerText.trim())
            }
            idu++
        }

        setAlertConfirm({ ...alertConfirm, open: !alertConfirm.open, data: reportData })
    }
    // Get the name of card selected and find the column where
    // is the card selected and remove then put it in the column 0
    function removeEmployees(event: any, index: number) {
        const columns = document.querySelectorAll('.column')
        let cardName = ''
        switch (event.target.localName) {
            case 'button':
                cardName = event.target.parentNode.innerText
                break;
            case 'svg':
                cardName = event.target.parentNode.parentNode.innerText
                break;
            case 'path':
                cardName = event.target.parentNode.parentNode.parentNode.innerText
                break;

            default:
                break;
        }

        let columnIndx = 0
        columns.forEach(column => {
            column.childNodes.forEach(empCard => {
                if (columnIndx > 0 && empCard.textContent == cardName) {
                    columns[columnIndx].removeChild(empCard)
                    columns[0].append(empCard)
                }
            });
            columnIndx++
        });
    }

    return props.isOpen.open ? (
        (
            <div className="drag-employees">
                <div className="quadrilles">

                    <Report0Seeker />
                    <button className="btn-report btn-back" onClick={comeBackForm}> Regresar </button>
                    <button className="btn-report btn-select" onClick={() => history.push('/admin/quadrilles')}> Ver otro </button>

                    <h5>Asignar cuadrillas</h5>

                    <div className="quadrilles-container">
                        <div className="employees">
                            <p>Personal</p>
                            <div className="column">
                                {
                                    employees.length ? (
                                        employees.map((employee, index) =>
                                            <div
                                                draggable="true"
                                                className="employee-card"
                                                key={index}
                                                onDragStart={(event) => dragStart(event)}
                                                onDragEnd={(event) => dragEnd(event)}
                                            >
                                                <button onClick={(event) => removeEmployees(event, index)}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                                    </svg>
                                                </button>
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z" />
                                                </svg>
                                                <p>{employee['name']}</p>
                                            </div>
                                        )
                                    ) : (<p>Cargando...</p>)

                                }
                            </div>

                        </div>
                        {
                            generateColumns()
                        }

                    </div>

                    <button className="btn-report btn-next" onClick={validate}>
                        Continuar
                    </button>

                    <AlertConfirmReport0 isOpen={alertConfirm} onClose={() => setAlertConfirm({ ...alertConfirm, open: !alertConfirm.open })} />
                </div>
            </div>
        )
    ) : null
}

export default DragEmployees