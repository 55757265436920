import { useEffect, useState } from "react"

// Firebase
import firebase from '../../services/firebase/Config'

// Interfaces
import ServicesContext from "./ServicesContext"
import Service from "../../interfaces/service/Service"
import ServiceData from "../../interfaces/service/ServiceData"

export const initialValues = {
    name: '',
    description: '',
    company: '',
    createdAt: 0,
    endDate: 0
}

const Services: React.FC = (props) => {

    const firestore = firebase.firestore()

    const [companies, setCompanies] = useState([])
    const [services, setServices] = useState([])
    const [serviceSelected, setServiceSelected] = useState([])

    useEffect(() => {
        getCompanies()
        getServices()
    }, [])

    function getCompanies () {
        firestore.collection('companies').onSnapshot(snap => {
            let docs: any = []
            snap.forEach(company => {
                docs.push(company.data())
            })
            setCompanies(docs)
        })
    }

    function getServices() {
        firestore.collection('services').onSnapshot(snap => {
            let docs: any = []
            snap.forEach(service => {
                docs.push({ ...service.data(), id: service.id })
            })
            setServices(docs)
        })
    }

    function showsServiceSelected (service: any) {
        if (Object.values(service).length === services.length) {
            return setServiceSelected([])
        }
        
        let docs: any = []
        docs.push(service)
        return setServiceSelected(docs)
    }

    async function createService (service: Service) {
        service.createdAt = new Date().getTime()
        await firestore.collection('companies').doc().set({
            company: service.company
        })
        return await firestore.collection('services').doc().set(service)
    }

    async function editService (service: ServiceData) {
        return await firestore.collection('services').doc(service['id']).update(service)
    }

    async function deleteService (serviceId: string) {
        return await firestore.collection('services').doc(serviceId).delete()
    }

    const value = {
        companies, services, serviceSelected, showsServiceSelected, createService, editService, deleteService
    }

    return (
        <ServicesContext.Provider value= {value}>
            {props.children}
        </ServicesContext.Provider>
    )
}

export default Services