import { createContext } from "react";
import Report0 from "../../../interfaces/reports/report0/Report0";

const Report0Context = createContext({
    // Employees
    employees: [],
    // Services
    services: [],
    getServices: (limit: number) => {},
    // Reports
    reports: [],
    report: [],
    getReport: (index: number) => {},
    createReport: (report: Report0, firstCreated: boolean) => {},
    updateReport: (report: any, id: number) => {},
    deleteReport: (reports: any, id: number) => {}
})

export default Report0Context