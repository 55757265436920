export const initialValues = {
    quadrilles: 0,
    services: 0,
    type: ''
}

interface Report0 {
    employees: any,
    services: any
}

export default Report0