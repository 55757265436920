import { useEffect } from 'react'
import { BrowserRouter as Switch, Route, BrowserRouter } from 'react-router-dom'

// Context Data
import LandingPageData from './context/landingPage/LandingPageData'
import UserContext from './context/auth/AuthState'
import ImageHomeData from './context/imageHome/ImageHomeData'
import ServiceWSData from './context/servicesWS/ServicesWSData'
import PromotionData from './context/promotions//PromotionData'
import AdminContext from './context/admin/AdminData'
import ProductsData from './context/products/ProductsData'
import CategoryContextData from './context/categories/CategoryData'
import EmployeesData from './context/employees/EmployeesData'
import ServicesData from './context/services/ServicesData'
import Report0Data from './context/reports/report0/Report0Data'

// Libraries
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Components and CSS
import './App.css';

/* ------ Components ------ */
// ------ Web Site
import HomeWS from './pages/home/Home'
import AboutUs from './pages/aboutUs/AboutUs'
import Services from './pages/servicesWS/ServicesWS'
// ------ Auth
import SignIn from './components/admin/auth/SignIn'
// ------ Admin
// Home
import Home from './components/admin/home/Home';
import Navbar from './components/admin/navbar/Navbar';
// Admin Home
import ServicesAdmin from './components/admin/services/Services'
// Landing Page Admin
import ImageHome from './components/admin/website/imagesHome/ImagesCarousel';
import ServicesWS from './components/admin/website/services/ServicesWS'
import Promotion from './components/admin/website/promotion/Promotions'
// Employees
import Employees from './components/admin/employees/Employees'
// Products
import Products from './components/admin/products/Products'
// Categories
import Categories from './components/admin/categories/Categories'
// Vehicles
import Vehicles from './components/admin/vehicles/Vehicles'
// Servicios
import Service from './components/admin/services/service/Service'
// ------ Reports
// Report 0
import Report0 from './components/admin/reports/report0/Report0'
import ShowReport0 from './components/admin/reports/report0/show/Show'
// Report 1
import Report1 from './components/admin/reports/report1/Report1'

// Routes
import PrivateRoutes from './services/routes/privateRoutes'
import PublicRoutes from './services/routes//publicRoutes'
import VehicleData from './context/vehicles/VehicleData'
import Report1Data from './context/reports/report1/Report1Data'

function App() {

  useEffect(() => {
    document.title = "Arpitechnology"
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <LandingPageData>
            {/* ------------ WebSite -------------- */}
            <PublicRoutes exact path="/" >
              <HomeWS />
            </PublicRoutes>
            <PublicRoutes path="/about-us" activeClassName="nav-link-active" >
              <AboutUs />
            </PublicRoutes>
            <PublicRoutes path="/services" >
              <Services />
            </PublicRoutes>
          </LandingPageData>
          <UserContext>
            <AdminContext>
              <CategoryContextData>
                <ImageHomeData>
                  <ServiceWSData>
                    <PromotionData>
                      <ProductsData>
                        <EmployeesData>
                          <VehicleData>
                            <ServicesData>
                              <Report0Data>
                                <Report1Data>
                                  {/* ------------ Admin -------------- */}
                                {/* Admin */}
                                <PrivateRoutes exact path="/admin">
                                  <Home />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Categories */}
                                <PrivateRoutes path="/admin/categories">
                                  <Categories />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Products */}
                                <PrivateRoutes path="/admin/products">
                                  <Products />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Admin Website */}
                                <PrivateRoutes exact path="/admin/website-images-home">
                                  <ImageHome />
                                  <Navbar />
                                </PrivateRoutes>
                                <PrivateRoutes exact path="/admin/website-services">
                                  <ServicesWS />
                                  <Navbar />
                                </PrivateRoutes>
                                <PrivateRoutes exact path="/admin/website-promotions">
                                  <Promotion />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Employees */}
                                <PrivateRoutes exact path="/admin/employees">
                                  <Employees />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Vehicles */}
                                <PrivateRoutes exact path="/admin/vehicles">
                                  <Vehicles />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Services */}
                                <PrivateRoutes exact path="/admin/services">
                                  <ServicesAdmin />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Selected Service */}
                                <PrivateRoutes path="/admin/services/:id">
                                  <Service />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* ------------ Reports -------------- */}
                                {/* Report 0 */}
                                <PrivateRoutes path="/admin/report-0">
                                  <Report0 />
                                  <Navbar />
                                </PrivateRoutes>
                                <PrivateRoutes path="/admin/quadrilles">
                                  <ShowReport0 />
                                  <Navbar />
                                </PrivateRoutes>
                                {/* Report 1 */}
                                <PrivateRoutes path="/admin/report-1">
                                  <Report1 />
                                  <Navbar />
                                </PrivateRoutes>
                                </Report1Data>
                              </Report0Data>
                            </ServicesData>
                          </VehicleData>
                        </EmployeesData>
                      </ProductsData>
                    </PromotionData>
                  </ServiceWSData>
                </ImageHomeData>
              </CategoryContextData>
            </AdminContext>
            <Route exact path="/admin/signin">
              <SignIn />
            </Route>
          </UserContext>
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
