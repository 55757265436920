//Components and CSS
import { useEffect, useRef, useState } from 'react'
import './Loader.css'

export default function Loader() {

    const [isLoading, setIsLoading] = useState(false)
    
    const loaderContainer1 = useRef<HTMLDivElement>(null)
    const loaderContainer2 = useRef<HTMLDivElement>(null)
    const loaderContent = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(true)
        }, 2000);

        if (isLoading) { handleStyles() }
    })

    function handleStyles () {
        const lines = loaderContent.current?.querySelectorAll('.line')

        lines?.forEach(line => {
            line.classList.add('disappear')
        })

        setTimeout(() => {
            loaderContainer1.current?.classList.add('inactive')
        }, 500);
        loaderContainer2.current?.classList.add('inactive')
        loaderContent.current?.classList.add('inactive')
    }

    return (
        <div className="loader-container">
            <div ref={loaderContainer1} className="loader-container-1" />
            <div ref={loaderContainer2} className="loader-container-2" />
            <div ref={loaderContent} className="loader-content">
                <div className="line-container">
                    <div className="line line-1" />
                    <div className="line line-2" />
                    <div className="line line-3" />
                </div>
            </div>
        </div>
    )
}