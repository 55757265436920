// Libraries
import emailjs from 'emailjs-com';
// import { ContactUs } from '../../../../services/nodemailer/contactForm/contactFormEmailer'

// Components and CSS
import { useState } from 'react'
import { toast } from 'react-toastify'
import './contactForm.css'

export default function ContactForm() {

    const [dataForm, setDataForm] = useState({
        name: '',
        email: '',
        phone: '',
        validate: ''
    })

    const [errors, setErrors] = useState({
        nameInput: '',
        emailInput: ''
    })

    function handleInputCharged(event: any) {
        const { name, value } = event.target
        setDataForm({ ...dataForm, [name]: value })
    }

    function validations(e: any) {
        if (!dataForm.name.length) {
            setErrors({ ...errors, nameInput: 'error' })
            return toast('El campo título no puede estar vacío', {
                type: "warning"
            })
        }
        setErrors({
            nameInput: '',
            emailInput: ''
        })

        if (!dataForm.email.length) {
            setErrors({ ...errors, emailInput: 'error' })
            return toast('El campo correo electrónico no puede estar vacío', {
                type: "warning"
            })
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(dataForm.email)) {
            setErrors({ ...errors, emailInput: 'error' })
            return toast('Ingrese un correo electrónico válido', {
                type: "warning"
            })
        }

        setErrors({
            nameInput: '',
            emailInput: ''
        })

        try {
            // ContactUs(dataForm)
            sendEmail(e)
        } catch (error) {
            console.log(error);

        }
        // handleSubmit()

    }

    function sendEmail(e: any) {
        e.preventDefault()
        
        emailjs.sendForm("service_sq81yjn", "template_cic4cuw", e.target, 'user_66qPnJq4Yqekvm4gAZX7o')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        
            setDataForm({
                name: '',
                email: '',
                phone: '',
                validate: ''
            })

            setErrors({
                nameInput: '',
                emailInput: ''
            })
    }

    return (
        <div className="contact">
            <p className="title">Contáctanos</p>
            <p>En breve nos comunicaremos contigo...</p>
            <form onSubmit={sendEmail}>
                <input
                    className={dataForm.name.length > 0 && dataForm.name.length < 4 ? 'error' : ''}
                    type="text"
                    required
                    name="name"
                    value={dataForm.name}
                    autoComplete="off"
                    autoSave="off"
                    placeholder="Nombre (*)"
                    onChange={(e) => handleInputCharged(e)} />
                {
                    dataForm.name.length > 0 && dataForm.name.length < 4 ? (
                        <p className="alert">El campo nombre debe contener mínimo 4 caracteres</p>
                    ) : (<></>)
                }
                <input
                    type="email"
                    className={
                        dataForm.email.length > 0 &&
                        !new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(dataForm.email) ? 'error' : ''}
                    required={true}
                    name="email"
                    value={dataForm.email}
                    autoComplete="off"
                    autoSave="off"
                    placeholder="Correo electrónico (*)"
                    onChange={(e) => handleInputCharged(e)} />
                {
                    dataForm.email.length > 0 &&
                    !new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(dataForm.email) ? (
                        <p className="alert">El formato del correo electrónico debe ser válido</p>
                    ) : (<></>)
                }
                <input
                    className={dataForm.phone.length <= 9 && dataForm.phone.length > 0 ? ('error') : ''}
                    type="number"
                    name="phone"
                    value={dataForm.phone}
                    autoComplete="off"
                    autoSave="off"
                    maxLength={10}
                    placeholder="Teléfono (opcional)"
                    onChange={(e) => handleInputCharged(e)} />
                {
                    dataForm.phone.length <= 9 && dataForm.phone.length > 0 ? (
                        <p className="alert">El campo teléfono debe contener 10 caracteres</p>
                    ) : (<></>)
                }
                <input type="text"
                    name="validate"
                    autoComplete="off"
                    autoSave="off"
                    placeholder="Teléfono (opcional)"
                    hidden />
                {
                    dataForm.name.length > 3 &&
                    new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(dataForm.email) &&
                    !dataForm.validate ? (
                        <button className="btn-contact" type="submit">
                            Contactar
                        </button>
                    ) : (<p>Llene los campos de forma correcta!</p>)
                }
            </form>
        </div>
    )
}