import React, { useEffect, useRef, useState } from 'react'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { CreateImageHomeModal } from '../../../admin/website/promotion/modal/Create/Create'
import './Datepicker.css'

interface DatepickerInterface {
    isOpen: boolean,
    onClose: () => void,
    datepickerData: (datepicker: any) => void
}

export const DatepickerRange: React.FC<DatepickerInterface> = ({ isOpen, onClose, datepickerData }) => {
    const initDatePickerValues = {
        day: '',
        month: '',
        year: 0

    }
    // Date Month
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    // QuerySelector
    const ulMonth = useRef<HTMLUListElement>(null)
    const ulYear = useRef<HTMLUListElement>(null)
    const daysMonthRef = useRef<HTMLUListElement>(null)

    const [datepicker, setDatepicker] = useState(initDatePickerValues)
    // Arr of days of month
    const [days, setDays] = useState([])
    const [daysDefault, setDaysDefault] = useState([])
    // Arr of days of year
    const [yearsDefault, setYearsDefault] = useState([])

    useEffect(() => {
        showYears()
        getDaysOfThisMonth()
    }, [])

    // Date-Picker functions
    function getDaysOfThisMonth() {
        let date = new Date()
        let arrDays: any = []
        if (!datepicker.month) {
            setDatepicker({
                ...initDatePickerValues,
                day: '',
                month: months[date.getMonth()],
                year: date.getFullYear()

            })
        }
        for (let i = 1; i < (new Date(2021, (date.getMonth() + 1), 0).getDate() + 1); i++) {
            arrDays.push(i)
        }
        setDaysDefault(arrDays)
    }
    function toogleActiveMonth() {
        if (ulMonth.current?.classList.contains('active')) {
            ulMonth.current?.classList.remove('active')
        } else {
            ulMonth.current?.classList.add('active')
        }
    }
    function toogleActiveYear() {
        if (ulYear.current?.classList.contains('active')) {
            ulYear.current?.classList.remove('active')
        } else {
            ulYear.current?.classList.add('active')
        }
    }
    function getDays(month: string, index: number) {
        let arrDays: any = []
        setDatepicker({
            ...datepicker,
            day: '',
            month: month,
            year: datepicker.year

        })
        for (let i = 1; i < (new Date(2021, (index + 1), 0).getDate() + 1); i++) {
            arrDays.push(i)
        }
        setDays(arrDays)
        toogleActiveMonth()
    }
    function showYears() {
        const thisYear = new Date().getFullYear()
        let startYear = 1990
        let years: any = []
        
        while (startYear <= thisYear) {
            years.push(startYear)
            startYear++
        }
        setYearsDefault(years)
    }
    function selectRangeDate(index: number) {
        let date = new Date()
        const li = daysMonthRef.current?.querySelectorAll('li')
        let liActive = 0

        li?.forEach(days => {

            if (days.classList.contains('active')) {
                days.classList.remove('active')
            }
            if (parseInt(days.innerText) === index) {
                days.classList.add('active')
                if (liActive === 0) {
                    setDatepicker({
                        ...datepicker,
                        day: days.innerText,
                        month: datepicker.month,
                        year: datepicker.year

                    })
                }
            }
        })

        if (liActive === 1) {
            li?.forEach(days => {
                days.classList.remove('active')
            })
        }
    }

    function selectYear (year: number) {
        setDatepicker({ ...datepicker, year: year })
        toogleActiveYear()
    }

    function validate () {
        if (!datepicker.month) {
            return toast('Seleccione un mes para poder continuar', {
                type: 'warning'
            })
        }
        if (!datepicker.year) {
            return toast('Seleccione un año para poder continuar', {
                type: 'warning'
            })
        }
        if (!datepicker.day) {
            return toast('Seleccione un día para poder continuar', {
                type: 'warning'
            })
        }

        datepickerData(datepicker)
        onClose()
    }

    return isOpen ? (
        <div className="date-range-container">
            <div className="date-container">
                <button className="btn-close-datepicker" onClick={onClose}>
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                </button>
                <div className="date-select-container">
                    <div className="month-container">
                        <input type="text" readOnly placeholder="Seleccione un mes" onClick={toogleActiveMonth} value={datepicker.month} />
                        <ul ref={ulMonth}>
                            {
                                months.map((month, index) =>
                                    <li key={index} onClick={() => getDays(month, index)}>{month}</li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="year-container">
                        <input type="text" readOnly placeholder="Seleccione un año" onClick={toogleActiveYear} value={datepicker.year} />
                        <ul ref={ulYear}>
                        {
                            yearsDefault.map((year, index) =>
                                <li key={index} onClick={() => selectYear(year)}>{year}</li>
                            )
                        }
                    </ul>
                    </div>
                </div>
                <div className="days-container">
                    <ul ref={daysMonthRef}>
                        {
                            days.length ? (
                                days.map((index) =>
                                    <li key={index} onClick={() => selectRangeDate(index)}>{index}</li>
                                )
                            ) : (
                                daysDefault.map((index) =>
                                    <li key={index} onClick={() => selectRangeDate(index)}>{index}</li>
                                )
                            )

                            // <li onClick={(e) => selectRangeDate(e)}>x</li>
                        }
                    </ul>
                </div>
                <button className="btn btn-date-accept" type="button" onClick={validate}>
                    Aceptar
                </button>
            </div>
            {/* <CreateImageHomeModal isOpen={true} onClose={() => false} datepicker={sendData} /> */}
        </div>
    ) : null
}