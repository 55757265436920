import { useEffect, useState } from "react"

// Components and CSS
import './Service.css'

import { useParams } from "react-router"

// Firebase
import firebase from '../../../../services/firebase/Config'

export default function Service() {

    const { id }: any = useParams()

    const firestore = firebase.firestore()

    const date = new Date()

    const [serviceData, setServiceData] = useState([])

    useEffect(() => {
        getService(id)
    }, [])

    async function getService(serviceId: string) {
        let serviceData: any = []
        await firestore.collection('services').doc(serviceId).get().then(service => {
            serviceData.push(service.data())
        })
        setServiceData(serviceData)
    }



    return (
        <div className="grid-admin-page">
            <div className="blank-space" />
            {
                serviceData.length ? (
                    serviceData.map((service, index) =>
                        <div className="service-container" key={index}>
                            <h3>Datos del servicio {service['name']}</h3>
                            <div className="service-data-container">
                                <div className="service-data">
                                    <div className="tag"></div>
                                    <p>{service['name']}</p>
                                    <p>{service['description']}</p>
                                    <div className="text-data">
                                        <p>Solicitado: <strong>{service['company']}</strong></p>
                                        <p>Creado:
                                            {
                                                service['createdAt'] > 0 ? (
                                                    <span>{date.getDate() + ' / ' + (date.getMonth() + 1) + ' / ' + date.getFullYear()}</span>
                                                ) : (<span></span>)
                                            }
                                        </p>
                                        <p>Terminado:
                                            {
                                                service['endDate'] > 0 ? (
                                                    <span>{date.getDate() + ' / ' + (date.getMonth() + 1) + ' / ' + date.getFullYear()}</span>
                                                ) : (<span className="no-finish">No terminado</span>)
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="material-requested grid col-2 small-col-1">
                                    <div className="service-data">
                                        <p>Material solicitado</p>
                                        <div className="products-container">
                                            <div className="data">
                                                <p>Material total solicitado:</p>
                                                <p>257 productos</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-data">
                                        <p>Material utilizado</p>
                                        <div className="products-container">
                                            <div className="data">
                                                <p>Material total utilizado:</p>
                                                <p>200 productos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="material-requested grid col-2 small-col-1">
                                    <div className="service-data">
                                        <p>Material sobrante</p>
                                        <div className="products-container">
                                            <div className="data">
                                                <p>Material total sobrante:</p>
                                                <p>57 productos</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-data">
                                        <p>Control vehicular</p>
                                        <div className="products-container">
                                            <div className="data">
                                                <p>Material total utilizado:</p>
                                                <p>257 productos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="material-requested grid col-2 small-col-1">
                                    <div className="service-data">
                                        <p>Cuadrillas</p>
                                        <div className="products-container">
                                            <div className="quadrille-container">
                                                <p>Cuadrillas que han participado:</p>
                                                <div className="quadrilles-data">
                                                    <div className="quadrille">
                                                        Cuadrilla 1
                                                    </div>
                                                    <div className="quadrille">
                                                        Cuadrilla 2
                                                    </div>
                                                    <div className="quadrille">
                                                        Cuadrilla 3
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                ) : (<p>Cargando...</p>)
            }
        </div>
    )
}