import { useEffect, useRef, useState } from 'react'

// Components and CSS
import './Promotions.css'

// Firestore
import firebase from '../../../services/firebase/Config'

interface PromotionModal {
    isOpen: boolean,
    onClose: () => void
}

export const PromotionsModal: React.FC<PromotionModal> = ({ isOpen, onClose, children }) => {
    // Date Months
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const firestore = firebase.firestore()
    const [promotions, setPromotions] = useState([])
    const [month, setMonth] = useState<string>()
    const promotionModalRef = useRef<HTMLDivElement>(null)
    const promotionRef = useRef<HTMLDivElement>(null)
    const btnRightRef = useRef<HTMLButtonElement>(null)
    const btnLefttRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (!promotions.length) {
            getPromotions()
            getDate()
        }
    }, [promotions])

    function getPromotions() {
        firestore.collection('Promotions').onSnapshot(response => {
            const docs: any = []
            response.forEach(promotion => {
                docs.push({ ...promotion.data(), id: promotion.id })
            })
            setPromotions(docs)
        })
    }

    function getDate () {
        const today = new Date()
        setMonth(months[( today.getMonth() )])
    }

    function handleStylesPromotionModal() {
        promotionModalRef.current?.classList.add('disappear')

        setTimeout(() => {
            onClose()
        }, 500);
    }

    function hiddenElementsPromotionModal(promotionSelected: number, type: string) {
        const promotions = promotionRef.current?.querySelectorAll('.data')
        if (type === 'right' && promotionSelected === (promotions!.length - 2)) {
            btnRightRef.current?.classList.add('disabled')
            btnLefttRef.current?.classList.remove('disabled')
        }
        if (type === 'left' && promotionSelected === 0) {
            btnLefttRef.current?.classList.add('disabled')
            btnRightRef.current?.classList.remove('disabled')
        }

        promotions?.forEach(promotion => {
            if (type == 'right') {
                if ((promotionSelected + 1) <= promotions.length) {
                    if (promotion === promotions[promotionSelected + 1]) {
                        promotion.classList.add('appear-right')
                        promotion.classList.remove('disappear')
                    } else {
                        promotion.classList.remove('appear-right')
                        promotion.classList.add('disappear')
                    }
                }
            }
            if (type == 'left') {
                if ((promotionSelected - 1) >= 0) {
                    if (promotion === promotions[promotionSelected - 1]) {
                        promotion.classList.add('appear-left')
                        promotion.classList.remove('disappear')
                    } else {
                        promotion.classList.add('disappear')
                        promotion.classList.remove('appear-left')
                    }
                }
            }
        })
    }



    return isOpen ? (
        <div className="promotions">
            <div ref={promotionModalRef} className="promotions-content">
                {/* <div className="modal-header">
                    <button className="btn-close-modal" onClick={onClose}>
                        x
                    </button>
                    
                    <h5>Servicios Arpi Technology</h5>
                </div> */}
                <div className="promotions-header">
                    <h5>Promociones de { month }</h5>

                    <button onClick={handleStylesPromotionModal}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>

                <div ref={promotionRef} className="promotions-body">
                    {
                        promotions.map((promotion, index) =>
                            <div className="data" key={index}>
                                <div className="promotion-image">
                                    <button ref={btnLefttRef} onClick={() => hiddenElementsPromotionModal(index, 'left')}>
                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                                        </svg>
                                    </button>
                                    <img src={promotion['urlImage']} alt="Promotion Image" />
                                    <button ref={btnRightRef} onClick={() => hiddenElementsPromotionModal(index, 'right')}>
                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                        </svg>
                                    </button>
                                </div>
                                <p>{promotion['title']}</p>
                                <p>
                                    {promotion['mainDescription']}
                                </p>
                                {
                                    promotion['extraInformation1'] ? (
                                        <p>{promotion['extraInformation1']}</p>
                                    ) : <></>
                                }
                                {
                                    promotion['extraInformation2'] ? (
                                        <p>{promotion['extraInformation2']}</p>
                                    ) : <></>
                                }
                                <p>
                                    <strong>
                                        {promotion['initialDate']['day'] + '/' + promotion['initialDate']['month'] + '/' + promotion['initialDate']['year']}
                                    </strong> 
                                        &nbsp; al &nbsp;
                                    <strong> 
                                        {promotion['endDate']['day'] + '/' + promotion['endDate']['month'] + '/' + promotion['endDate']['year']}
                                    </strong>

                                </p>
                                <p>Promoción {(index + 1)} de {promotions.length}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    ) : null
}