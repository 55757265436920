import { useContext, useState } from 'react'

// Context
import EmployeesContext from '../../../context/employees/EmployeesContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import CreateEmployeesModal from './modal/create/Create'
import EditEmployeesModal from './modal/edit/Edit'
import EmployeeSeeker from './seeker/Seeker'
import './Employees.css'
import { initialAlertDataValues } from '../../../interfaces/alert/Alert'
import { Alert } from '../../globals/alert/Alert'

// Interfaces

export default function Employees() {

    const { employees, employeeSelected, deleteEmployee } = useContext(EmployeesContext)

    // Open Create Modal
    const [createProductModal, setCreatProductModal] = useState<boolean>(false)

    // Open Edit Modal
    const [editEmployeeModal, setEditEmployeeModal] = useState({
        open: false,
        employee: {}
    })

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function deleteEmployees(employee: any) {
        // console.log(employee);
        try {
            deleteEmployee(employee)
            toast('Empleado eliminado con éxito!', {
                type: 'success'
            })
        } catch (error) {
            toast('Hubo un problema y no se pudo eliminar el empleado!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page">
            <div className="blank-space" />

            <div className="employees">
                <EmployeeSeeker />

                <button className="btn-add" onClick={() => setCreatProductModal(!createProductModal)}>
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg> Agregar
                </button>

                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Área laboral</th>
                            <th>Fecha inicio laboral</th>
                            <th>Código de barras</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !employeeSelected.length ? (
                                employees.map((employee, index) =>
                                    <tr key={index}>
                                        <td data-label="ID">{(index + 1)}</td>
                                        <td data-label="Nombre">{employee['name']}</td>
                                        <td data-label="Área laboral">{employee['laborArea']}</td>
                                        <td data-label="Inicio laboral">{employee['dateOfSeniority']['day'] + ' / ' + employee['dateOfSeniority']['month'] + ' / ' + employee['dateOfSeniority']['year']}</td>
                                        {/* <td data-label="Código de barras">{product['barcode']}</td> */}
                                        <td data-label="Acciones">
                                            <div className="btn-container">
                                                <button onClick={() => setEditEmployeeModal({
                                                    open: !editEmployeeModal.open,
                                                    employee
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar empleado', text: ('¿Desea eliminar al empleado ' + employee['name'] + '?'), data: employee })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ) : (

                                employees.map((employee, index) =>
                                    <tr key={index}>
                                        <td data-label="ID">{(index + 1)}</td>
                                        <td data-label="Nombre">{employee['name']}</td>
                                        <td data-label="Área laboral">{employee['laborArea']}</td>
                                        <td data-label="Fecha inicio laboral">{employee['dateOfSeniority']['day'] + ' / ' + employee['dateOfSeniority']['month'] + ' / ' + employee['dateOfSeniority']['year']}</td>
                                        {/* <td data-label="Código de barras">{product['barcode']}</td> */}
                                        <td data-label="Acciones">
                                            <div className="btn-container">

                                                {/* <button onClick={() => setEditProductModal({
                                                    open: !editProductModal.open,
                                                    product: product
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar imágen', text: ('¿Desea eliminar la imagen ' + product['name'] + '?'), data: product })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>

            </div>

            <CreateEmployeesModal isOpen={createProductModal} onClose={() => setCreatProductModal(!createProductModal)} />
            <EditEmployeesModal isOpen={editEmployeeModal} onClose={() => setEditEmployeeModal({ ...editEmployeeModal, open: !editEmployeeModal.open })} />
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={() => deleteEmployees(alertOpen.data)} />
        </div>
    )
}