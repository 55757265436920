import firebase from 'firebase/app'
import "firebase/auth";

const app = firebase.initializeApp({
    apiKey: "AIzaSyBaQUVfTKLvSe6vazg-97o9VQFFEAcueyU",
    authDomain: "arpitechnology-5454e.firebaseapp.com",
    databaseURL: "https://arpitechnology-5454e-default-rtdb.firebaseio.com",
    projectId: "arpitechnology-5454e",
    storageBucket: "arpitechnology-5454e.appspot.com",
    messagingSenderId: "741852410800",
    appId: "1:741852410800:web:6c0d5a38df65b2b1ca5c75",
    measurementId: "G-52GP0BHK8V"
})

export const auth = firebase.auth()

export default app