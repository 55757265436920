import { createContext } from 'react'

interface ServiceWS {
    id: string,
    title: string,
    description: string
}

const ServiceWSContext = createContext({
    services: [],
    getServices: () => {},
    addService: (service: ServiceWS, image: string) => {},
    editService: (service: ServiceWS, image: string) => {},
    deleteService: (serviceId: string) => {},
    storage: (image: any) => {}
})

export default ServiceWSContext