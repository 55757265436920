import { createContext } from 'react'

interface User {
    name: string,
    email: string
}

const AuthContext = createContext({
    user: {email: ''},
    signin: (email:string, password:string) => {},
    signup: (email:string, password:string) => {},
    logout: () => {},
    createUser: (user: User) => {},
})

export default AuthContext