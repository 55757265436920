import { useContext, useEffect, useState } from 'react'

// Context
import ProductContext from '../../../../../context/products/ProductsContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from '../../../../globals/alert/Alert'
import './Add.css'

// Interfaces
import AddProductModal from '../../../../../interfaces/product/ProductDataModal'
import { initialAlertValues } from '../../../../../interfaces/alert/Alert'

const AddProductsModal: React.FC<AddProductModal> = ({ isOpen, onClose, children }) => {

    const { addProducts, productSelected, showProductSelected } = useContext(ProductContext)

    const initialValues = {
        id: isOpen.product['id'] || '',
        name: isOpen.product['name'] || '',
        SKU: isOpen.product['SKU'] || '',
        createdAt: isOpen.product['createdAt'] || 0,
        barcode: isOpen.product['barcode'] || '',
        price: isOpen.product['price'] || '',
        stock: isOpen.product['stock'] || '',
        image: isOpen.product['image'] || '',
        category: isOpen.product['category'] || ''
    }

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    const [product, setProduct] = useState(initialValues)
    const [newValueStock, setNewValueStock] = useState<number>(0)

    useEffect(() => {
        setProduct(initialValues)
    }, [isOpen.product])

    function validations() {
        if (!product.stock) {
            return toast('El campo cantidad para stock no puede estar vacio', {
                type: 'warning'
            })
        }

        setAlertOpen({...alertOpen, alertOpen: true, title: 'Agregar producto', text: ('¿Desea agregar ' + newValueStock + ' elementos al producto ' + product['name'].toLocaleLowerCase()  + '?')})
    }

    function handleSubmit () {
        try {
            addProducts(product, newValueStock)
            setNewValueStock(0)
            toast('Elementos agregados al producto con éxito', {
                type: 'success'
            })
            if (productSelected.length) {
                showProductSelected(product)
            }
            onClose()
        } catch (error) {
            toast('Ocurrió un problema y no se pudo agregar los elementos al producto!', {
                type: 'error'
            })
        }
    }

    return isOpen.open ? (
        <div className="add">
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Agregar producto</h5>

                        <button className="btn-close-modal" onClick={() => onClose()}>
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="input-container">
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                                </svg>
                                <input
                                    type="number"
                                    name="stock"
                                    required
                                    value={newValueStock}
                                    autoComplete="off"
                                    autoSave="off"
                                    autoFocus={true}
                                    onChange={(event) => setNewValueStock(parseInt(event.target.value))}
                                />
                                <span>Cantidad para stock</span>
                            </div>

                            <button className="btn-submit" type="submit" onClick={validations}>
                                Agregar
                        </button>
                        </form>
                    </div>
                </div>
            </div>

            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={handleSubmit} />
        </div>
    ) : null
}

export default AddProductsModal