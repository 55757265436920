import { useEffect, useState } from "react"

// Context
import CategoryContext from "./CategoryContext"

// Firestore
import firebase from '../../services/firebase/Config'
import Category from "../../interfaces/category/Category"
import CategoryData from "../../interfaces/category/CategoryData"
const firestore = firebase.firestore()


const CategoryContextData:React.FC = (props) => {

    const [categories, setCategories] = useState([])
    const [categorySelected, setCategorySelected] = useState([])

    useEffect(() => {
        getCategories()
    }, [])

    function getCategories () {
        firestore.collection('categories').onSnapshot(res => {
            let docs: any = []
            res.forEach(category => {
                docs.push({ ...category.data(), id: category.id })
            });
            setCategories(docs)
        })
    }

    function showCategorySelected (category: Category) {
        if (Object.values(category).length === categories.length) {
            return setCategorySelected([])
        }
        
        let docs: any = []
        docs.push(category)
        return setCategorySelected(docs)
    }

    async function createCategory (category: Category) {
        return await firestore.collection('categories').doc().set(category)
    }

    async function editCategory (category: CategoryData) {
        return await firestore.collection('categories').doc(category.id).update(category)
    }

    async function deleteCategory (categoryId: string) {
        return await firestore.collection('categories').doc(categoryId).delete()
    }

    const value = {
        categories, getCategories, categorySelected, showCategorySelected, createCategory, editCategory, deleteCategory
    }

    return (
        <CategoryContext.Provider value={value}>
            {props.children}
        </CategoryContext.Provider>
    )
}

export default CategoryContextData