import { useContext, useState } from 'react'

// Context

// Libraries
import { toast } from 'react-toastify'

// Components and CSS

// Interfaces
import CategoryModal from '../../../../../interfaces/category/CategoryModal'
import { initialAlertValues } from '../../../../../interfaces/alert/Alert'
import { Alert } from '../../../../globals/alert/Alert'
import CategoryContext from '../../../../../context/categories/CategoryContext'
import Category from '../../../../../interfaces/category/Category'

const CreateCategoryModal: React.FC<CategoryModal> = (props) => {

    const initialValues = {
        name: ''
    }

    const { createCategory } = useContext(CategoryContext)

    const [categories, setCategories] = useState<Category>(initialValues)

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    function validations() {
        if (!categories) {
            return toast('El campo nombre no puede estar vacio', {
                type: 'warning'
            })
        }
        
        setAlertOpen({...alertOpen, alertOpen: true, title: 'Crear categoria', text: ('¿Desea crear la categoria ' + categories.name.toLocaleLowerCase()  + '?')})
    }

    function handleSubmit () {
        try {
            createCategory(categories!)
            toast('Categoria registrada con éxito', {
                type: 'success'
            })
            setCategories(initialValues)
            props.onClose()
        } catch (error) {
            toast('Ocurrió un problema y no se pudo crear la categoria!', {
                type: 'error'
            })
        }
    }

    return props.isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Crear categoria</h5>

                    <button className="btn-close-modal" onClick={props.onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">

                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="name"
                                required
                                value={categories?.name}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => setCategories({ name: event.target.value })}
                            />
                            <span>Nombre</span>
                        </div>
                        {/* onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar categoria', text: ('¿Desea eliminar la categoria ' + product['name'] + '?'), data: product })} */}
                        <button className="btn-submit" type="submit"  onClick={validations}>
                            Crear
                        </button>
                    </form>

                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={handleSubmit} />
        </div>
    ) : null
}

export default CreateCategoryModal