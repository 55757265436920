import { useEffect, useState } from "react"

// Context
import Report0Context from "./Report0Context"

// Firebase
import firebase from '../../../services/firebase/Config'

const Report0: React.FC = (props) => {

    const firestore = firebase.firestore()

    const date = new Date()
    const today = (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear())

    const [employees, setEmployees] = useState([])
    const [services, setServices] = useState([])
    const [reports, setReports] = useState([])
    const [report, setReport] = useState([])

    useEffect(() => {
        checkReport()
        getEmployees()
    }, [])

    function checkReport () {
        return firestore.collection('reports').doc(today).onSnapshot(snap => {
            let docs: any = []
            docs.push(snap.data())
            setReports(docs)
        })
    }

    async function getReport(index: number) {
        let docs: any = []
        return await firestore.collection('reports').doc(today).get().then(snap => {
            docs.push(snap.data())
            setReport(docs[0]['quadrilles'][index])
        })
    }

    function getEmployees () {
        return firestore.collection('employees').onSnapshot(snap => {
            let docs: any = []
            snap.forEach(employee => {
                docs.push(employee.data())
            })
            setEmployees(docs)
        })
    }

    function getServices (limit: number) {
        firestore.collection('services').limitToLast(limit).orderBy('createdAt', 'desc').get().then(snap => {
            let docs: any = []
            snap.forEach(service => {
                docs.push({ ...service.data(), id: service.id })
            })
            setServices(docs)
        })
    }

    async function createReport (report: any, firstCreated: boolean) {
        const object = {
            quadrilles: firstCreated ? report : report[0],
            report: 'second'
        }
        
        await firestore.collection('reports').doc(today).set({...object})
    }

    async function updateReport (report: any, id: number) {
        await report[0].splice(id, 1)
        
        const object = {
            quadrilles: report[0],
            report: 'second'
        }
        await firestore.collection('reports').doc(today).update(object)
    }

    async function deleteReport (reports: any, id: number) {
        await reports[0]['quadrilles'].splice(id, 1)
        await firestore.collection('reports').doc(today).update(reports[0])
    }

    const value = {
        // Reports
        reports, report, getReport,
        // Employees
        employees,
        // Services
        services, getServices, createReport, updateReport, deleteReport
    }

    return (
        <Report0Context.Provider value={value}>
            {props.children}
        </Report0Context.Provider>
    )
}

export default Report0