import { useContext, useEffect, useState } from 'react'

// Components and CSS
import './Show.css'

import ProductDataModal from '../../../../../interfaces/product/ProductDataModal'
import ProductsContext from '../../../../../context/products/ProductsContext'

const ShowProductsModal: React.FC<ProductDataModal> = ({ isOpen, onClose, children }) => {

    const { categories, getCategories } = useContext(ProductsContext)

    const date = new Date()

    const initialValues = {
        id: isOpen.product['id'] || '',
        name: isOpen.product['name'] || '',
        SKU: isOpen.product['SKU'] || '',
        createdAt: date.getTime() || 0,
        barcode: isOpen.product['barcode'] || '',
        price: isOpen.product['price'] || '',
        stock: isOpen.product['stock'] || '',
        image: isOpen.product['image'] || '',
        category: isOpen.product['category'] || ''
    }

    const [product, setProduct] = useState(initialValues)

    const [productBSelectedBase64, setProductBSelectedBase64] = useState('')

    useEffect(() => {
        getCategories()
        setProduct(initialValues)
        return setProductBSelectedBase64(isOpen.product.image)
    }, [isOpen.product])

    return isOpen.open ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Ver producto</h5>

                    <button className="btn-close-modal" onClick={() => onClose()}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div className="show-modal-body modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="name"
                                required
                                value={product.name}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                readOnly
                            />
                            <span>Nombre</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H11V19.13L19.39 10.74C19.83 10.3 20.39 10.06 21 10M14 4.5L19.5 10H14V4.5M22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19M19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83Z" />
                            </svg>
                            <input
                                type="text"
                                name="SKU"
                                required
                                value={product.SKU}
                                autoComplete="off"
                                autoSave="off"
                                readOnly
                            />
                            <span>SKU</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
                            </svg>
                            <input
                                type="number"
                                name="price"
                                required
                                value={product.price}
                                autoComplete="off"
                                autoSave="off"
                                readOnly
                            />
                            <span>Precio</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" />
                            </svg>
                            <input
                                type="number"
                                name="stock"
                                required
                                value={product.stock}
                                autoComplete="off"
                                autoSave="off"
                                readOnly
                            />
                            <span>STOCK</span>
                        </div>
                        <div className="input-container">
                            <select disabled className="select" name="category" value={product.category} onChange={(event) => setProduct({ ...product, category: event.target.value })}>
                                {
                                    categories.map((category, index) =>
                                        <option key={index} value={category['name']}>{category['name']}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z" />
                            </svg>
                            <input
                                type="text"
                                name="barcode"
                                required
                                value={product.barcode}
                                autoComplete="off"
                                autoSave="off"
                                readOnly
                            />
                            <span>Código de barras</span>
                        </div>
                        <div className="show-image-selected-container">
                            <div className="show-product">
                                <p>Imágen del producto:</p>
                                {
                                    productBSelectedBase64 ? (
                                        <img src={productBSelectedBase64} alt="" />
                                    ) : (<p className="no-image">Ninguno seleccionado.</p>)
                                }
                            </div>
                        </div>
                        <button className="btn-submit" type="submit" onClick={onClose}>
                            Cerrar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    ) : null
}

export default ShowProductsModal