import React, { useEffect, useState } from 'react'
import AdminContext from './AdminContext'


// Firestore
import firebase from '../../services/firebase/Config'
const firestore = firebase.firestore()

const Admin: React.FC = (props: any) => {

    const [imagesHomeSize, setImagesHomeSize] = useState<number>(0)
    const [promotionsSize, setPromotionsSize] = useState<number>(0)
    const [servicesSize, setServicesSize] = useState<number>(0)

    useEffect(() => {
        getImages()
        getPromotions()
        getServies()
    }, [])

    function getImages() {
        firestore.collection('ImagesHome').onSnapshot(response => {
            setImagesHomeSize(response.size)
        })
    }

    function getPromotions() {
        firestore.collection('Promotions').onSnapshot(response => {
            setPromotionsSize(response.size)
        })
    }

    function getServies() {
        firestore.collection('ServicesWS').onSnapshot(response => {
            setServicesSize(response.size)
        })
    }

    const value = {
        imagesHomeSize, promotionsSize, servicesSize
    }

    return (
        <AdminContext.Provider value = { value }>
            {props.children}
        </AdminContext.Provider>
    )
}

export default Admin