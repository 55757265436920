import { useEffect, useState } from "react"

// Firebase
import firebase from '../../services/firebase/Config'

// Context
import EmployeesContext from "./EmployeesContext"

// Interfaces
import Employee from "../../interfaces/employee/Employee"
import EmployeeData from "../../interfaces/employee/EmployeeData"

export const initialValues = {
    name: '',
    dateOfSeniority: {
        day: '',
        month: '',
        year: ''
    },
    laborArea: ''
}

const Employees:React.FC = (props: any) => {

    const firestore = firebase.firestore()

    const [employees, setEmployees] = useState([])
    const [employeeSelected, setEmployeeSelected] = useState([])

    useEffect(() => {
        getEmployees()
    }, [])

    function getEmployees () {
        return firestore.collection('employees').onSnapshot(snap => {
            let docs: any = []
            snap.forEach(employee => {
                docs.push({ ...employee.data(), id: employee.id })
            })
            setEmployees(docs)
        })
    }

    function showEmployeeSelected (employee: any) {
        if (Object.values(employee).length === employees.length) {
            return setEmployeeSelected([])
        }
        
        let docs: any = []
        docs.push(employee)
        return setEmployeeSelected(docs)
    }

    async function createEmployee (employee: Employee) {
        return await firestore.collection('employees').doc().set(employee)
    }

    async function editEmployee (employee: EmployeeData) {
        return await firestore.collection('employees').doc(employee.id).update(employee)
    }

    async function deleteEmployee (employee: EmployeeData) {
        return await firestore.collection('employees').doc(employee.id).delete()
    }

    const value = {
        employees, employeeSelected, showEmployeeSelected, createEmployee, editEmployee, deleteEmployee
    }

    return (
        <EmployeesContext.Provider value={value}>
            {props.children}
        </EmployeesContext.Provider>
    )
}

export default Employees