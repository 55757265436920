import { useContext, useEffect, useRef, useState } from 'react'

// Context
import EmployeeContext from '../../../../../context/employees/EmployeesContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { DatepickerRange } from '../../../../globals/datepicker/range/Datepicker'
import { Alert } from '../../../../globals/alert/Alert'
import './Edit.css'

// Interfaces
import { initialAlertValues } from '../../../../../interfaces/alert/Alert'
import EmployeeDataModal from '../../../../../interfaces/employee/EmployeeDataModal'

const EditEmployeesModal: React.FC<EmployeeDataModal> = ({ isOpen, onClose, children }) => {
    
    const { editEmployee } = useContext(EmployeeContext)

    const initialValues = {
        id: isOpen.employee['id'] || '',
        name: isOpen.employee['name'] || '',
        dateOfSeniority: isOpen.employee.dateOfSeniority || {},
        laborArea: isOpen.employee.laborArea
    }

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)
    const [datepickerOpen, setDatepickerOpen] = useState<boolean>(false)

    const [employee, setEmployee] = useState(initialValues)
    
    useEffect(() => {
        // getCategories()
        if (isOpen.employee.dateOfSeniority) {
            setEmployee(initialValues)
        }
    }, [isOpen.employee])

    function handleInputCharged (event: any) {
        const { name, value } = event.target
        setEmployee({ ...employee, [name]: value })
    }

    function validations() {
        if (!employee.name) {
            return toast('El campo nombre no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!employee.dateOfSeniority) {
            return toast('El campo fecha de antiguedad no puede estar vacio', {
                type: 'warning'
            })
        }

        setAlertOpen({...alertOpen, alertOpen: true, title: 'Editar empleado', text: ('¿Desea editar al empleado ' + employee['name'].toLocaleLowerCase()  + '?')})
    }

    function handleSubmit () {
        try {
            editEmployee(employee)
            toast('Empleado actualizado con éxito', {
                type: 'success'
            })
            setEmployee(initialValues)
            // if (productSelected.length) {
            //     showProductSelected(product)
            // }
            onClose()
        } catch (error) {
            toast('Ocurrió un problema y no se pudo actualizar el empleado!', {
                type: 'error'
            })
        }
    }

    return isOpen.open ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Editar empleado</h5>

                    <button className="btn-close-modal" onClick={() => onClose()}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="name"
                                required
                                value={employee.name}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Nombre</span>
                        </div>
                        
                        <div className="input-container">
                            <select className="select" name="category" value={employee.laborArea} onChange={(event) => setEmployee({ ...employee, laborArea: event.target.value })}>
                                <option disabled  value={''}>Seleccione un área laboral</option>
                                <option value="administración">Administración</option>
                                <option value="campo">Campo</option>
                            </select>
                        </div>
                        <div className="date-container">
                            <button className="btn-date" type="button" onClick={() => setDatepickerOpen(!datepickerOpen)}>
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V8H19V19Z" />
                                </svg>
                            </button>
                            <div className="date-info">
                                <div className="date-of-seniority">
                                    <p>Fecha de inicio laboral</p>
                                    {
                                        employee.dateOfSeniority.day ? (
                                            <p>{employee.dateOfSeniority['day']}/
                                                {employee.dateOfSeniority['month']}/
                                                {employee.dateOfSeniority['year']}</p>
                                        ) : (
                                            <p className="no-date">No ha seleccionado ninguna fecha</p>
                                        )
                                    }
                                </div>
                                
                            </div>
                        </div>
                        <DatepickerRange isOpen={datepickerOpen} onClose={() => setDatepickerOpen(!datepickerOpen)} datepickerData={(datepicker) => setEmployee({ ...employee, dateOfSeniority: datepicker })} />
                        <button className="btn-submit" type="submit" onClick={validations}>
                            Editar
                        </button>
                    </form>
                </div>
            </div>

            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={handleSubmit} />
        </div>
    ) : null
}

export default EditEmployeesModal