import { createContext } from 'react'

interface ImageHome {
    id: string,
    title: string,
    description: string
}

const ImageHomeContext = createContext({
    imagesHome: [],
    getImages: () => {},
    addImage: (imageHome: ImageHome, image: string) => {},
    editImage: (imageHome: ImageHome, image: string) => {},
    deleteImage: (idImage: string) => {},
    storage: (image: any) => {}
})

export default ImageHomeContext