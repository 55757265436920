// Components and CSS
import { useState } from "react";
import Loader from "../../components/globals/loader/Loader";
import ContactForm from "../../components/webSite/contactForm/contactForm";
import FooterWS from "../../components/webSite/footer/FooterWS";
import NavbarHome from "../../components/webSite/navbar/NavbarHome";
import { PromotionsModal } from "../../components/webSite/promotions/Promotions";
import './AboutUs.css'

export default function AboutUs() {

    // Promotion Modal Props
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    return (
        <div className="about-us">
            <Loader />

            <NavbarHome />

            <div className="about-us-header">
                <div className="card-info">
                    <p className="title">Misión</p>
                    <p>
                        Ser un socio comercial estratégico para la integración de servicios y soluciones especializadas en telecomunicaciones y seguridad tecnológica de primer nivel para nuestros clientes, cumpliendo con los más altos estándares de calidad y con esto ser sus mejores aliados, contribuyendo con ello en el incremento de la rentabilidad de sus negocios.
                    </p>
                </div>
                <div className="card-info card-info-gray">
                    <p className="title">Visión</p>
                    <p>
                        Ser la mejor opción en el mercado para la integración de soluciones en telecomunicaciones y seguridad tecnológica, para llegar a ser reconocidos a nivel nacional como una empresa que ofrece soluciones innovadoras de vanguardia tecnológica , con especial actitud y alta capacidad para emprender, desarrollar , implementar, resolver y operar los retos impuestos por nuestros clientes.
                    </p>
                </div>
                <div className="card-info">
                    <p className="title">Valores</p>
                    <p>
                        Ética , calidad y conﬁanza en la realización de nuestros servicios, ofreciendo a nuestros clientes la garantía de nuestro profesionalismo y experiencia; actuando con honestidad, Integridad, lealtad, respeto, responsabilidad y conﬁdencialidad, buscando siempre la satisfacción de nuestros clientes y coolaboradores.
                    </p>
                </div>
            </div>

            <div className="about-us-body">
                <div className="certifications-container">
                    <p className="title">Certificaciones</p>
                    <div className="card-info-container grid col-3 mid-col-2 small-col-1">
                        <div className="card-certifications-info">
                            <p className="">Panduit</p>
                            <p>
                                En Panduit, se le otorga mayor prioridad a la calidad y confiabilidad de los productos y servicios. Todo el personal de nuestra organización está dedicado a proporcionar a nuestros clientes productos y servicios que respondan a sus necesidades.
                            </p>
                        </div>
                        <div className="card-certifications-info">
                            <p>Seguridad</p>
                            <p>
                                La Certificación en Protección Profesional (CPP) proporciona pruebas demostrables de los conocimientos que se posee en las ocho áreas estratégicas de la Seguridad. Aquellos que obtienen el grado de CPP son acreditados por la Junta de Certificaciones.
                            </p>
                        </div>
                        <div className="card-certifications-info">
                            <p>Cableado estructurado</p>
                            <p>
                                El proceso de certificación del cableado estructurado que realiza Unitel, tras la consecución de una instalación, es un proceso por el cual se compara el rendimiento de transmisión de un sistema de cableado instalado con un estándar determinado.
                            </p>
                        </div>
                    </div>
                </div>

                <ContactForm />
            </div>

            <button className="btn-promotion" onClick={() => setModalOpen(!modalOpen)}>
                <svg className="svg-icon" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z" />
                </svg>
            </button>

            <PromotionsModal isOpen={modalOpen} onClose={() => setModalOpen(!modalOpen)} />

            <FooterWS />
        </div>
    )
}