// Components and CSS
import { useContext, useRef, useState } from 'react'

// Context
import CategoryContext from '../../../../context/categories/CategoryContext'

// Components and CSS
import './Seeker.css'

// Interfaces
import Category from '../../../../interfaces/category/Category'

export default function CategorySeeker() {

    const inputSeekerRef = useRef<HTMLInputElement>(null)
    const ulSeekerRef = useRef<HTMLUListElement>(null)

    const { categories, showCategorySelected } = useContext(CategoryContext)
    const [categoryFound, setCategoryFound] = useState([])

    function filterData(event: string) {
        let dataSearched = categories.filter((category: Category) => {
            if (category.name.toLocaleLowerCase().indexOf(event.toLocaleLowerCase()) > -1) {
                return category
            }
        })
        setCategoryFound(dataSearched)

        if (inputSeekerRef.current?.value) {
            ulSeekerRef.current?.classList.add('active')
        }

        if (!inputSeekerRef.current?.value) {
            showCategorySelected(categories)
        }
    }

    function hideUlItems(category: Category) {
        showCategorySelected(category)
        ulSeekerRef.current?.classList.remove('active')
    }

    return (
        <div className="category-search">
            <div className="text">
                <h4>Categorias</h4>
            </div>
            <div className="search-container">
                <input ref={inputSeekerRef} className="input-search" type="text" placeholder="Buscar categoria..." onChange={(event) => filterData(event.target.value)} />

                <div className="data-searched">
                    <ul ref={ulSeekerRef}>
                        {
                            inputSeekerRef.current?.value ? (
                                categoryFound.map((category, index) =>
                                    <li className="category-item-searched" key={index} onClick={() => hideUlItems(category)} >{category['name']}</li>
                                )) : (<></>)
                        }
                        {
                            inputSeekerRef.current?.value && !categoryFound.length ? (
                                <li className="category-item-searched" >
                                        No existe ningúna categoria con ese nombre!
                                    </li>
                            ) : (<></>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}