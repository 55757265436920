//Context
import ServicesWSContext from '../../../../context/servicesWS/ServiceWSContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from '../../../globals/alert/Alert'
import { CreateServiceWSModal } from './modal/create/Create'
import { EditServiceWSeModal } from './modal/edit/Edit'
import { useContext, useEffect, useRef, useState } from 'react'
import './ServicesWS.css'

export default function ServicesWS() {
    const initialValuesEditModal = {
        isOpen: false,
        serviceData: {},
        image: {}
    }
    const initialAlertValues = {
        alertOpen: false,
        text: '',
        title: '',
        image: true,
        serviceValue: {}
    }

    // Data of ServicesWSContext
    const { services, getServices, deleteService } = useContext(ServicesWSContext)

    // Modal
    const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
    const [isOpenEditModal, setOpenEditModal] = useState(initialValuesEditModal)

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    const cardBody = useRef<HTMLDivElement>(null)

    useEffect(() => {
        return getServices()
    }, [])

    function toogleBtnActions (index: number) {
        const elements = cardBody.current?.querySelectorAll('.btn-options')
        var count = 0
        
        elements?.forEach(div => {
            if (index == count) {
                if (div.classList.contains('active')) {
                    div.classList.remove('active')
                } else {
                    div.classList.add('active')
                }
            }
            count++
        })
    }

    function removeService(service: any) {
        try {
            deleteService(service['id'])
            toast('Servicio eliminado de la página web!', {
                type: 'success'
            })
        } catch (error) {
            toast('No se pudo eliminar el servicio de la página web!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page">
            <div className="blank_space" />
            <div className="services-ws">
                <div className="services-ws-header">
                    <h4>Servicios de Arpi Technology</h4>
                    <button className="btn-add" onClick={() => setOpenCreateModal(!isOpenCreateModal)}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg> Agregar
                    </button>
                </div>

                <div className="services-ws-body" ref={cardBody}>
                    <div className="cards-container grid col-3 mid-col-2 small-col-1">
                        {
                            services.length ?
                            services.map((service, index) => 
                                <div className="card" key={index}>
                                    <div className="image-container">
                                        <img src={service['urlImage']} alt="ImageHome" />
                                    </div>
                                    <div className="info">
                                        <p>{service['title']}</p>
                                        <p>{service['description']}</p>
                                        <div className="display-flex">
                                            <p>24-01-2021</p>
                                            <div className="btn-actions">
                                                <button className="btn-edit" onClick={() => setOpenEditModal({ ...initialValuesEditModal, isOpen: !isOpenEditModal.isOpen, serviceData: service})}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button className="btn-delete" onClick={() => setAlertOpen({...alertOpen, alertOpen: true, title: 'Eliminar servicio', text: ('¿Desea eliminar el servicio ' + service['title']  + '?'), serviceValue: service})}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            // <div className="card" key={index}>
                            //     <div className="card-image">
                            //         {
                            //             service['urlImage'] ? (
                            //                 <img src={service['urlImage']} alt=""/>
                            //             ) : (<p>Este servicio no tiene imágen.</p>)
                            //         }
                            //         {/* <img className="image" src={process.env.PUBLIC_URL + '/assets/prueba1.jpg'} alt="Logo ATG" /> */}
                            //     </div>
                            //     <div className="card-content">
                            //         <p>{service['title']}</p>
                            //         <p>
                            //             {service['description']}
                            //         </p>

                            //         <button className="btn-dots" onClick={() => toogleBtnActions(index)}>
                            //             <svg className="svg-icon" viewBox="0 0 24 24">
                            //                 <path fill="currentColor" d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" />
                            //             </svg>
                            //         </button>

                            //         <div  className="btn-options">
                            //             <ul>
                            //                 <li onClick={() => setOpenEditModal({ ...initialValuesEditModal, isOpen: !isOpenEditModal.isOpen, imageHomeData: service})}>
                            //                     <svg className="svg-icon" viewBox="0 0 24 24">
                            //                         <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                            //                     </svg> Editar
                            //                 </li>
                            //                 <li>
                            //                     <svg className="svg-icon" viewBox="0 0 24 24">
                            //                         <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                            //                     </svg> Eliminar
                            //                 </li>
                            //             </ul>
                            //         </div>

                            //         <hr/>

                            //         <button className="button btn-card-go">
                            //             <svg className="svg-icon" viewBox="0 0 24 24">
                            //                 <path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                            //             </svg>
                            //         </button>
                                    
                            //     </div>
                            // </div>
                            ) : (<></>)
                        }
                    </div>
                </div>
            </div>
            <CreateServiceWSModal isOpen={isOpenCreateModal} onClose={() => setOpenCreateModal(!isOpenCreateModal)} />
            <EditServiceWSeModal isOpen={isOpenEditModal.isOpen} data={isOpenEditModal.serviceData} onClose={() => setOpenEditModal({ ...initialValuesEditModal, isOpen: !isOpenEditModal.isOpen})} />
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={() => removeService(alertOpen.serviceValue)} />
        </div>
    )
}