export const initialValues = {
    brand: '',
    model: '',
    description: '',
    observations: '',
    totalLts: '',
    laborArea: '',
    image: ''
}

interface Vehicle {
    brand: string
    model: string
    description: string
    observations: string
    totalLts: string
    laborArea: string,
    image: string
}

export default Vehicle