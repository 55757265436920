import React from 'react'

// CSS
import './Alert.css'

// Interfaces
import AlertInterface from '../../../interfaces/alert/Alert'

export const Alert:React.FC<AlertInterface> = ({ alertIsOpen, data, onClose, accept, children }) => {
    
    function confirm () {
        accept()
        onClose()
    }
    
    return alertIsOpen ? (
        <div className="__alert__wrapper">
            <div className={data.image ? "__alert__container" : "__alert__container not-image"}>
                <div className="__alert__header">
                    <button className="__close__alert" onClick={onClose}>x</button>
                    <p>{data.title}</p>
                </div>
                <div className="__alert__body">
                    <p>{data.text}</p>
                    {
                        data.image ? (<p className=""></p>) : null 
                        // (<p className="not-image">No tiene imágen... ¿Desea continuar?</p>)
                    }
                </div>
                <div className="__alert__footer">
                    <button onClick={confirm}>
                        {data.title}
                    </button>
                    <button onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    ): null
}