// React
import React, { useContext, useEffect, useRef, useState } from 'react'

// Libraries
import { toast } from 'react-toastify'

//Context
import ServiceWSContext from '../../../../../../context/servicesWS/ServiceWSContext'

// Components and CSS
import { Alert } from '../../../../../globals/alert/Alert'
import './Edit.css'

interface EditProps {
    isOpen: boolean,
    data: any,
    // alertResponse: (type: string) => void,
    onClose: () => void
}

export const EditServiceWSeModal: React.FC<EditProps> = ({isOpen, data, onClose, /*alertResponse,*/ children}) => {    
    const initialValues = {
        id: data['id'] || '',
        title: data['title'] || '',
        description: data['description'] || ''
    }
    const initialAlertValues = {
        id: '',
        alertOpen: false,
        text: '',
        title: '',
        image: false
    }
    const modalRef = useRef(null)
    const { editService, storage } = useContext(ServiceWSContext)
    const [dataImageHome, setDataImageHome] = useState(initialValues)
    const imageRef = useRef<HTMLInputElement>(null)
    const [imageSelected, setImageSelected] = useState()
    const [imageSelectedBase64, setImageSelectedBase64] = useState<string>('')

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)
    
    useEffect(() => {
        if (data) {
            setDataImageHome({...initialValues})
            setAlertOpen({...alertOpen, id: data['id']})
            setImageSelectedBase64(data['urlImage'])
        }
    }, [data])

    function handleInputCharged(event: any) {
        const { name, value } = event.target
        setDataImageHome({ ...dataImageHome, [name]: value })
    }

    function selectImage (event: any) {
        if (event.target.files[0]) {
            setImageSelected(event.target.files[0])
            // Load image selected in image input
            const reader = new FileReader();
            reader.onload = function(e) {
                // The file's text will be printed here
                setImageSelectedBase64(e.target?.result as string)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    function validations () {
        if (!dataImageHome.title) {
            return toast('El campo título no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!dataImageHome.description) {
            return toast('El campo descripción no puede estar vacio', {
                type: 'warning'
            })
        }

        if (imageSelectedBase64) {
            return setAlertOpen({...alertOpen, alertOpen: true, title: 'Editar servicio', text: ('¿Desea editar el servicio ' + dataImageHome['title'].toLocaleLowerCase()  + '?'), image: true})
        }
        setAlertOpen({...alertOpen, alertOpen: true, title: 'Editar servicio', text: ('¿Desea editar elservicio ' + dataImageHome['title'].toLocaleLowerCase()  + '?')})
    }

    function deleteImage() {
        setImageSelectedBase64('')
        setImageSelected(undefined)
    }

    async function handleSubmit () {
        try {
            if (imageSelected) {
                storage(imageSelected)
                editService(dataImageHome, imageSelectedBase64)
            } else {
                editService(dataImageHome, '')
            }
            toast('Servicio eliminado con éxito!', {
                type: 'success'
            })
            onClose()
        } catch (error) {
            toast('No se pudo eliminar el servicio!', {
                type: 'error'
            })
        }
        setImageSelectedBase64('')
        setDataImageHome(initialValues)
    }

    return isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="btn-close-modal" onClick={onClose}>
                        x
                    </button>
                    
                    <h5>Editar servicio</h5>
                </div>
                <div className="modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input 
                                type="title"
                                name="title"
                                required
                                value={dataImageHome.title}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={ (event) => handleInputCharged(event) } 
                            />
                            <span>Título</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M22 13H14V11H22V13M22 7H14V9H22V7M14 17H22V15H14V17M12 9V15C12 16.1 11.1 17 10 17H4C2.9 17 2 16.1 2 15V9C2 7.9 2.9 7 4 7H10C11.1 7 12 7.9 12 9M10.5 15L8.3 12L6.5 14.3L5.3 12.8L3.5 15H10.5Z" />
                            </svg>
                            <textarea
                                rows={10}
                                name="description"
                                required
                                value={dataImageHome.description}
                                autoComplete="off"
                                autoSave="off"
                                onChange={ (event) => handleInputCharged(event)}
                            />
                            <span>Descripción</span>
                        </div>
                        <div className="image-selected-container display-flex">
                            <div>
                                <input ref={imageRef} accept="image/*" type="file" hidden onChange={selectImage} />
                                <button type="button" className="btn-image" onClick={() => imageRef.current?.click()}>
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z" />
                                    </svg>
                                </button>
                                {
                                    imageSelectedBase64 ? (
                                        <button type="button" className="btn-clear-image" onClick={() => deleteImage()}>
                                            <svg className="svg-icon" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                            </svg>
                                        </button>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="image-selected">
                                <p>Imágen seleccionada:</p>
                                {
                                    imageSelectedBase64 ? (
                                        <img src={imageSelectedBase64} alt=""/>
                                    ) : (<p>Ninguna.</p>)
                                }
                            </div>
                        </div>
                        <button className="btn-submit" type="submit" onClick={validations}>
                                Editar
                        </button>
                    </form>
                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={handleSubmit} />
        </div>
    ) : null
}