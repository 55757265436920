import { createContext } from "react";

// Interfaces
import Service from "../../interfaces/service/Service";
import ServiceData from "../../interfaces/service/ServiceData";


const ServicesContext = createContext({
    // Companies
    companies: [],

    // Services
    services: [],
    serviceSelected: [],
    showsServiceSelected: (service: any) => {},
    createService: (service: Service) => {},
    editService: (service: ServiceData) => {},
    deleteService: (service: string) => {}
})

export default ServicesContext