import { useContext, useEffect, useRef, useState } from "react"

// Context
import ServicesContext from "../../../../../context/services/ServicesContext"

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from "../../../../globals/alert/Alert"

// Interfaces
import ModalData from "../../../../../interfaces/modal/ModalData"
import ServiceData from "../../../../../interfaces/service/ServiceData"
import { initialAlertDataValues } from "../../../../../interfaces/alert/Alert"
import Company from "../../../../../interfaces/company/Company"


const EditServiceModal: React.FC<ModalData> = (props) => {
    
    const initialValues = {
        id: props.isOpen.data['id'] || '',
        name: props.isOpen.data['name'] || '',
        description: props.isOpen.data['description'] || '',
        company: props.isOpen.data['company'] || '',
        createdAt: props.isOpen.data['comcreatedAtpany'] || 0,
    }

    const { companies, editService } = useContext(ServicesContext)

    const ulRef = useRef<HTMLUListElement>(null)
    const companyRef = useRef<HTMLInputElement>(null)

    const [service, setService] = useState<ServiceData>(initialValues)
    const [companiesFiltered, setCompaniesFiltered] = useState([])

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    useEffect(() => {
        setService(initialValues)
    }, [props.isOpen.data])

    function validations () {
        if (!service.name) {
            return toast('El campo nombre no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!service.description) {
            return toast('El campo descripción no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!service.company) {
            return toast('El campo solicitado por no puede estar vacio', {
                type: 'warning'
            })
        }

        setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Editar servicio', text: ('¿Desea editar el servicio ' + service.name.toLocaleLowerCase() + '?') })
    }

    function handleChangeInput(event: string) {
        console.log(event);
        
        if (companyRef.current?.value) {
            ulRef.current?.classList.add('active')
        } else {
            ulRef.current?.classList.remove('active')
        }
        filterCompanyData(event)
        setService({ ...service, company: event })
    }

    function handleAddingStyles () {
        if (ulRef.current?.classList.contains('active')) {
            ulRef.current?.classList.remove('active')
        } else {
            ulRef.current?.classList.add('active')
        }
    }

    function filterCompanyData(companyToSearch: string) {
        let resCompaniesFilter = companies.filter((company: Company) => {
            if (company['company'].toLocaleLowerCase().indexOf(companyToSearch.toLocaleLowerCase()) > -1) {
                return company
            }
        });
        setCompaniesFiltered(resCompaniesFilter)
    }

    function selectCompany (company: Company) {
        setService({ ...service, company: company['company'] })
        handleAddingStyles()
    }

    function handleSubmit () {
        try {
            editService(service)
            toast('Servicio registrado con éxito', {
                type: 'success'
            })
            setService(initialValues)
            props.onClose()
        } catch (error) {
            toast('Ocurrió un problema y no se pudo crear el servicio!', {
                type: 'error'
            })
        }
    }

    return props.isOpen.open ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Editar servicio</h5>

                    <button className="btn-close-modal" onClick={props.onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">

                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="name"
                                required
                                value={service?.name}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => setService({ ...service, name: event.target.value })}
                            />
                            <span>Nombre</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z" />
                            </svg>
                            <input
                                type="text"
                                name="description"
                                required
                                value={service?.description}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => setService({ ...service, description: event.target.value })}
                            />
                            <span>Descripción</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z" />
                            </svg>
                            <input
                                ref={companyRef}
                                type="text"
                                name="company"
                                required
                                value={service?.company}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleChangeInput(event.target.value )}
                            />
                            <span>Solicitado por</span>
                            <div className="items">
                                <ul ref={ulRef}>
                                    {
                                        companiesFiltered.length ? (
                                            companiesFiltered.map((company, index) => 
                                            <li key={index} onClick={() => selectCompany(company)}>{company['company']}</li>
                                            )
                                        ) : (<></>)
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar categoria', text: ('¿Desea eliminar la categoria ' + product['name'] + '?'), data: product })} */}
                        <button className="btn-submit" type="submit" onClick={validations}>
                            Editar
                        </button>
                    </form>

                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={handleSubmit} />
        </div>
    ) : null
}

export default EditServiceModal