import { useEffect, useState } from 'react'
import AuthContext from './AuthContext'
import firebase, { auth } from '../../services/firebase/Config'
import 'firebase/firestore'

const firestore = firebase.firestore()

interface User {
    name: string,
    email: string
}

const AuthState: React.FC = (props: any) => {
    const [user, setUser] = useState<any>(null)
    const [loading, setLoading] = useState<Boolean>(true)

    /* -----------  TABLE USER  ------------ */

    async function createUser (user: User) {
        await firestore.collection('users').doc().set(user)
    }

    /* -----------  FIREBASE AUTH  ------------ */
    
    async function signin (email: string, password: string) {
        return await firebase.auth().signInWithEmailAndPassword(email, password)   
    }

    useEffect(() => {
        const unsubscribe =  auth.onAuthStateChanged(user => {
            setUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])
    
    async function signup (email: string, password: string) {
        return await firebase.auth().createUserWithEmailAndPassword(email, password)
    }
    
    function logout () {
        return auth.signOut()
    }

    const value = {
        user, signin, signup, logout, createUser
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && props.children}
        </AuthContext.Provider>
    )
}

export default AuthState