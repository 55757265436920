import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

// Contexto
import Report0Context from '../../../../../../context/reports/report0/Report0Context';

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from '../../../../../globals/alert/Alert';
import './Alert.css'

// Interfaces
import ModalData from "../../../../../../interfaces/modal/ModalData"
import { initialAlertDataValues } from '../../../../../../interfaces/alert/Alert';

const AlertConfirmReport0: React.FC<ModalData> = (props) => {
    
    const history = useHistory()

    const { reports, createReport, updateReport } = useContext(Report0Context)

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function generateData () {
        let x: any = []
        let firstCreated = false
        
        if (reports[0]) {
            reports.forEach(report => {
                x.push(report['quadrilles']);
            });
            

            x[0].push({...props.isOpen.data})
        } else {
            x.push({...props.isOpen.data})
            firstCreated = true
        }
        
        handleSubmit(x, firstCreated)
    }

    function handleSubmit(x: any, firstCreated: boolean) {
        try {
            if (localStorage.getItem('nextForm')) {
                createReport(x, firstCreated)
            } else {
                updateReport(x, parseInt(localStorage.getItem('nextFormEdit')?.split(',')[3]!))
            }
            toast('Reporte creado con éxito', {
                type: 'success'
            })
            localStorage.removeItem('nextForm')
            history.push('/admin')
        } catch (error) {
            console.log(error);
            toast('Hubo un problema y no se pudo crear el reporte', {
                type: 'error'
            })
        }
    }

    return props.isOpen.open ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Confirmación reporte 0</h5>

                    <button className="btn-close-modal" onClick={props.onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>

                <div className="alert-confirm modal-body">
                    <div>
                        {
                            props.isOpen.data.length ? (
                                props.isOpen.data.map((employee: any, index: number) =>
                                    <div className="report" key={index}>
                                        <p>Cuadrilla {(index + 1)}</p>
                                        <div className="column-data">
                                            {
                                                props.isOpen.data[index]['employees'].map((emp: any, i: number) =>
                                                    <div key={i}>
                                                        <p>{emp}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="service-container">
                                            <p>Servicio</p>
                                            <p>{props.isOpen.data[index]['service']}</p>
                                        </div>
                                    </div>
                                )
                            ) : (<p>Cargando...</p>)
                        }
                    </div>
                    <button className="btn-submit" onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Continuar', text: ('¿La formación de las cuadrillas es la correcta?') })}>
                        Continuar
                    </button>
                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={generateData} />
        </div>
    ) : null
}

export default AlertConfirmReport0