import { createContext } from "react";

// Interfaces
import Product from '../../interfaces/product/Product'
import EditProduct from '../../interfaces/product/EditProduct'

const ProductsContext = createContext({
    // Categories
    categories: [],
    getCategories: () => {},

    // Products
    products: [],
    productSelected: [],
    showProductSelected: (product: any) => {},
    createProducts: (product: Product, image: string) => {},
    addProducts: (product: EditProduct, stock: number) => {},
    editProducts: (product: EditProduct, image: string) => {},
    deleteProducts: (productId: string) => {}
})

export default ProductsContext