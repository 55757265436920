import { useContext, useState } from 'react'

// Libraries
import { toast } from 'react-toastify'

// Context
import VehicleContext from '../../../context/vehicles/VehicleContext'

// Components and CSS
import { Alert } from '../../globals/alert/Alert'
import CreateVehiclesModal from './modal/create/Create'
import './Vehicles.css'

// Interfaces
import { initialAlertDataValues } from '../../../interfaces/alert/Alert'
import { initialValues } from '../../../interfaces/modal/ModalData'
import EditVehicleModal from './modal/edit/Edit'

export default function Vehicles() {

    const { vehicles, deleteVehicle } = useContext(VehicleContext)

    // Open Create Modal
    const [createVehicleModal, setCreateVehicleModal] = useState<boolean>(false)

    // Open Edit Modal
    const [editVehicleModal, setEditVehicleModal] = useState(initialValues)

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function removeVehicle (vehicleId: any) {
        try {
            deleteVehicle(vehicleId.id)
            toast('Vehiculo eliminado con éxito!', {
                type: 'success'
            })
        } catch (error) {
            console.log(error);
            toast('Hubo un error y no se pudo eliminar el vehiculo!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page home">
            <div className="blank-space" />
            <div className="vehicles">
                <h4>Vehiculos</h4>
                <button className="btn-add" onClick={() => setCreateVehicleModal(!createVehicleModal)} >
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg> Agregar
                </button>

                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th></th>
                            <th>Marca</th>
                            <th>Modelo</th>
                            <th>Descripción</th>
                            <th>Observaciones</th>
                            <th>Lts</th>
                            <th>Área</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vehicles.length ? (
                                vehicles.map((vehicle, index) =>
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td><img src={vehicle['image']} alt="no imagen" /></td>
                                        <td>{vehicle['brand']}</td>
                                        <td>{vehicle['model']}</td>
                                        <td>{vehicle['description']}</td>
                                        <td>{vehicle['observations']}</td>
                                        <td>{vehicle['totalLts']}</td>
                                        <td>{vehicle['laborArea']}</td>
                                        <td data-label="Acciones">
                                            <div className="btn-container">
                                                <button onClick={() => setEditVehicleModal({
                                                    open: !editVehicleModal.open,
                                                    data: vehicle
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar imágen', text: ('¿Desea eliminar el vehículo ' + vehicle['brand'] + '?'), data: vehicle })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ) : null
                        }
                    </tbody>
                </table>
            </div>
            <CreateVehiclesModal isOpen={createVehicleModal} onClose={() => setCreateVehicleModal(!createVehicleModal)} />
            <EditVehicleModal  isOpen={editVehicleModal} onClose={() => setEditVehicleModal({ ...editVehicleModal, open: !editVehicleModal.open })} />
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={() => removeVehicle(alertOpen.data)} />
        </div>
    )
}