import { createContext } from "react";
import Category from "../../interfaces/category/Category";
import CategoryData from "../../interfaces/category/CategoryData";

const CategoryContext = createContext({
    categories: [],
    getCategories: () => {},
    categorySelected: [],
    showCategorySelected: (category: any) => {},
    createCategory: (category: Category) => {},
    editCategory: (category: CategoryData) => {},
    deleteCategory: (categoryID: string) => {}
})

export default CategoryContext