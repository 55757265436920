import { useContext, useEffect, useState } from "react"

// Context
import Report0Context from '../../../../../context/reports/report0/Report0Context'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import './Form.css'

// Interfaces
import { initialValues } from "../../../../../interfaces/reports/report0/Report0"
import DragEmployees from "../dragEmployees.tsx/DragEmployees"

export default function Form() {

    const { reports } = useContext(Report0Context)
    
    const [dataNextForm, setDataNextForm] = useState(initialValues)
    const [nextForm, setNextForm] = useState({
        open: false,
        data: dataNextForm
    })

    useEffect(() => {
        if (localStorage.getItem('nextForm')) {
            console.log(localStorage.getItem('nextForm'));
            
            setDataNextForm({ ...dataNextForm, quadrilles: parseInt(localStorage.getItem('nextForm')?.split(',')[0]!), services: parseInt(localStorage.getItem('nextForm')?.split(',')[1]!), type: localStorage.getItem('nextForm')?.split(',')[2]! })
            setNextForm({ ...nextForm, open: !nextForm.open })
        }
        if (localStorage.getItem('nextFormEdit')) {
            setDataNextForm({ ...dataNextForm, quadrilles: parseInt(localStorage.getItem('nextFormEdit')?.split(',')[0]!), services: parseInt(localStorage.getItem('nextFormEdit')?.split(',')[1]!), type: localStorage.getItem('nextFormEdit')?.split(',')[2]! })
            setNextForm({ ...nextForm, open: !nextForm.open })
        }
    }, [])

    function handleInputChange(event: any) {
        const { name, value } = event.target
        setDataNextForm({ ...dataNextForm, [name]: value })
    }

    function validateNextForm() {
        if (!dataNextForm.quadrilles) {
            return toast('El campo quadrillas no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!dataNextForm.services) {
            return toast('El campo servicios no puede estar vacio', {
                type: 'warning'
            })
        }

        if (!localStorage.getItem('nextFormEdit')) {
            localStorage.setItem('nextForm', String(dataNextForm.quadrilles) + ',' + String(dataNextForm.services) + ',' + 'create')   
        }
        if (localStorage.getItem('nextFormEdit')) {
            localStorage.setItem('nextFormEdit', String(dataNextForm.quadrilles) + ',' + String(dataNextForm.services) + ',' + 'edit' + ',' + localStorage.getItem('nextFormEdit')?.split(',')[3]! + ',' + 'true')
        }
        setNextForm({ ...nextForm, open: !nextForm.open, data: dataNextForm })
    }

    return (
        <div className="confirm-data">
            {
                !nextForm.open ? (
                    <div>
                        <h5>Generador de cuadrillas</h5>
                        <p>Ingrese la cantidad de cuadrillas de hoy</p>
                        <div className="input-container">
                            <input
                                type="number"
                                name="quadrilles"
                                required
                                value={dataNextForm.quadrilles}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleInputChange(event)}
                            />
                        </div>
                        <p>Últimos servicios</p>
                        <div className="input-container">
                            <input
                                type="number"
                                name="services"
                                required
                                value={dataNextForm.services}
                                autoComplete="off"
                                autoSave="off"
                                onChange={(event) => handleInputChange(event)}
                            />
                        </div>
                        {/* onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar categoria', text: ('¿Desea eliminar la categoria ' + product['name'] + '?'), data: product })} */}
                        <button className="btn-submit" type="button" onClick={validateNextForm}>
                            Generar
                        </button>
                    </div>
                ) : (
                    <DragEmployees isOpen={nextForm} onClose={() => setNextForm({ ...nextForm, open: !nextForm.open })} />
                )
            }
        </div>
    )
}