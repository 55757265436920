import { useEffect, useState } from "react"

// Context
import VehicleContext from "./VehicleContext"

// Firestore
import firebase from '../../services/firebase/Config'

// Components

// Interfaces
import Vehicle from "../../interfaces/vehicle/Vehicle"
import VehicleData from "../../interfaces/vehicle/VehicleData"

const Vehicles: React.FC = (props) => {

    const firestore = firebase.firestore()

    const [vehicles, setVehicles] = useState([])

    useEffect(() => {
        getVehicles()
    }, [])

    function getVehicles () {
        return firestore.collection('vehicles').onSnapshot(snap => {
            let docs: any = []
            snap.forEach(vehicle => {
                docs.push({...vehicle.data(), id: vehicle.id})
            })
            setVehicles(docs)
        })
    }

    async function createVehicle (vehicle: Vehicle) {
        return await firestore.collection('vehicles').doc().set(vehicle)
    }

    async function editVehicle (vehicle: VehicleData) {
        return await firestore.collection('vehicles').doc(vehicle.id).update(vehicle)
    }

    async function deleteVehicle (vehicleID: string) {
        return await firestore.collection('vehicles').doc(vehicleID).delete()
    }

    const value = {
        vehicles, createVehicle, editVehicle, deleteVehicle
    }

    return <VehicleContext.Provider value={value}>
        {props.children}
    </VehicleContext.Provider>
}

export default Vehicles