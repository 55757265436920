import { useContext, useEffect, useState } from "react";

// Context
import CategoryContext from "../../../../../context/categories/CategoryContext";

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { initialAlertValues } from "../../../../../interfaces/alert/Alert";

// Interfaces
import CategoryData from "../../../../../interfaces/category/CategoryData";
import CategoryDataModal from "../../../../../interfaces/category/CategoryDataModal";
import { Alert } from "../../../../globals/alert/Alert";

const EditCategoryModal: React.FC<CategoryDataModal> = ({ isOpen, onClose, children }) => {
    
    const { categorySelected, showCategorySelected, editCategory } = useContext(CategoryContext)

    const initialValues = {
        id: isOpen.category.id || '',
        name: isOpen.category.name || ''
    }

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    const [categories, setCategories] = useState<CategoryData>(initialValues)

    useEffect(() => {
        return setCategories(initialValues)
    }, [isOpen.category])

    function validations () {
        if (!categories?.name) {
            return toast('El campo nombre no puede estar vacio', {
                type: 'warning'
            })
        }

        setAlertOpen({...alertOpen, alertOpen: true, title: 'Editar categoria', text: ('¿Desea editar la categoria ' + categories['name'].toLocaleLowerCase()  + '?')})
    }

    function handleSubmit () {
        try {
            editCategory(categories)
            toast('Categoria actualizada con éxito', {
                type: 'success'
            })
            setCategories(initialValues)
            if (categorySelected.length) {
                showCategorySelected(categories)
            }
            onClose()
        } catch (error) {
            toast('Ocurrió un problema y no se pudo actualizar la categoria!', {
                type: 'error'
            })
        }
    }

    return isOpen.open ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Editar categoria</h5>

                    <button className="btn-close-modal" onClick={() => onClose()}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="name"
                                required
                                value={categories?.name}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => setCategories({ ...categories, name: event.target.value })}
                            />
                            <span>Nombre</span>
                        </div>
                        <button className="btn-submit" type="submit" onClick={validations}>
                            Editar
                        </button>
                    </form>
                </div>
            </div>

            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertValues, alertOpen: !alertOpen.alertOpen })} accept={handleSubmit} />
        </div>
    ) : null
}

export default EditCategoryModal