// Context
import LandingPage from '../../../context/landingPage/LandingPageContext'

// Components and CSS
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { PromotionsModal } from '../promotions/Promotions'
import './FooterWS.css'

export default function FooterWS() {

    const { promotions, getPromotions, redirect, showNotification } = useContext(LandingPage)
    const history = useHistory()

    // Promotion Modal Props
    const [modalOpen, setModalOpen] = useState<boolean>(false)


    useEffect(() => {
        if (!promotions.length) {
            getPromotions()
        }
    }, [promotions])

    return (
        <div className="home-footer">
            <div className="data-container grid col-4 mid-col-2 small-col-1">
                <div className="data data-1">
                    <p>Promociones</p>
                    <ul>
                        {
                            promotions.map((promotion, index) => 
                                <li onClick={() => setModalOpen(!modalOpen)} key={index} >{promotion['title']}</li>
                            )
                        }
                    </ul>
                </div>
                <div className="data data-2">
                    <p>Contáctanos</p>
                    <ul>
                        <li>871 581 8681</li>
                        <li>871 433 2518</li>
                        <li>contacto@arpitechnology.com.mx</li>
                        <li><a target="_blank" href="https://www.google.com/maps/place/25%C2%B033'59.2%22N+103%C2%B023'30.6%22W/@25.5664401,-103.3940104,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d25.5664401!4d-103.3918217?hl=es">Nos encontramos aquí</a></li>
                    </ul>
                </div>
                <div className="data data-3">
                    <p>Redes sociales</p>
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/arpi.technology">Instagram</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/arpi.tech">Facebook</a>
                        </li>
                    </ul>
                </div>
                <div className="data data-4">
                    <p>Menú</p>
                    <ul>
                        <li className={history.location.pathname == '/' ? 'active' : ''} onClick={() => redirect('/')} >Inicio</li>
                        <li className={history.location.pathname == '/about-us' ? 'active' : ''} onClick={() => redirect('/about-us')} >Acerca de</li>
                        <li className={history.location.pathname == '/services' ? 'active' : ''} onClick={() => redirect('/services')} >Servicios</li>
                        <li onClick={showNotification}>Casos de éxito</li>
                    </ul>
                </div>
            </div>
            <p>© 2020 Copyright: arpitechnology.com.mx</p>

            <PromotionsModal isOpen={modalOpen} onClose={() => setModalOpen(!modalOpen)} />
        </div>
    )
}