// Context
import LandingPage from '../../../context/landingPage/LandingPageContext'

// Components and CSS
import { useContext, useRef } from 'react'
import { useHistory } from 'react-router'
import './NavbarHome.css'

export default function NavbarHome() {

    const { handleAddingStyles, showNotification, goToPage } = useContext(LandingPage)

    const history = useHistory()
    const navbarRef = useRef<HTMLDivElement>(null)
    const navbarOptions = useRef<HTMLDivElement>(null)
    const navbarItems = useRef<HTMLUListElement>(null)
    const notificationRef = useRef<HTMLDivElement>(null)
    
    function redirect(page: string, type: string, element: string) {
        
        navbarRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });

        if (history.location.pathname === page && element === 'certifications') {
            return document.querySelector('.certifications-container')!.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
        
        if (element === 'contact-us') {
            return document.querySelector('.contact')!.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        if (history.location.pathname === page && element === 'services') {
            return document.querySelector('.services-header')!.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        if (history.location.pathname !== page && type === 'nav-bottom' && element === 'certifications') {
            setTimeout(() => {
                return document.querySelector('.certifications-container')!.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 1600);
        }

        if (history.location.pathname === page) {
            return document.querySelector('.navbar-home')!.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
        
        if (type === 'nav') {
            handleAddingStyles()
        } else {
            setTimeout(() => {
                handleAddingStyles()
            }, 400);
        }

        setTimeout(() => {
            goToPage(page)
        }, 1000);
    }

    function closeNavbarMobile (page: string) {
        if (navbarOptions.current?.classList.contains('show')) {
            navbarItems.current?.classList.add('remove')
            setTimeout(() => {
                navbarOptions.current?.classList.remove('show')
                if (page != '') {
                    redirect(page, 'nav', '')
                }
            }, 300);
        }
        navbarOptions.current?.classList.add('show')
        setTimeout(() => {
            navbarItems.current?.classList.remove('remove')
            if (page != '') {
                redirect(page, 'nav', '')
            }
        }, 300);
    }

    return (
        <div ref={navbarRef} className="navbar-home">
            <div ref={notificationRef} className="notification">
                <button onClick={() => notificationRef.current?.classList.remove('active')}>
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                </button>
                <p>Este apartado estará disponible próximamente!</p>
            </div>
            <div className="image-container" onClick={() => history.push('/')}>
                <img className="logo-image" src={process.env.PUBLIC_URL + '/assets/Logo.png'} alt="Logo Arpi Technology" />
            </div>
            <button className="btn-show-navbar-options" onClick={() => closeNavbarMobile('')}>
                <svg className="svg-icon" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M3,13H15V11H3M3,6V8H21V6M3,18H9V16H3V18Z" />
                </svg>
            </button>
            <div ref={navbarOptions} className="options">
                <ul ref={navbarItems} >
                    <button onClick={() => closeNavbarMobile('')}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                    <li className={history.location.pathname == '/' ? 'active' : ''} onClick={() => closeNavbarMobile('/')} >Inicio</li>
                    <li className={history.location.pathname == '/about-us' ? 'active' : ''} onClick={() => closeNavbarMobile('/about-us')} >Acerca de</li>
                    <li className={history.location.pathname == '/services' ? 'active' : ''} onClick={() => closeNavbarMobile('/services')} >Servicios</li>
                    <li onClick={showNotification}>Casos de éxito</li>
                </ul>
            </div>
            <div className="navbar-bottom">
                <ul>
                    <li onClick={() => redirect('/services', 'nav-bottom', 'services')} >
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L6.04,7.5L12,10.85L17.96,7.5L12,4.15Z" />
                        </svg>
                        <p>Servicios</p>
                    </li>
                    <li onClick={() => redirect('/about-us', 'nav-bottom', 'certifications')} >
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M4,3C2.89,3 2,3.89 2,5V15A2,2 0 0,0 4,17H12V22L15,19L18,22V17H20A2,2 0 0,0 22,15V8L22,6V5A2,2 0 0,0 20,3H16V3H4M12,5L15,7L18,5V8.5L21,10L18,11.5V15L15,13L12,15V11.5L9,10L12,8.5V5M4,5H9V7H4V5M4,9H7V11H4V9M4,13H9V15H4V13Z" />
                        </svg>
                        <p>Certificados</p>
                    </li>
                    <li onClick={() => redirect('/about-us', 'nav-bottom', 'contact-us')} >
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M13,8A4,4 0 0,1 9,12A4,4 0 0,1 5,8A4,4 0 0,1 9,4A4,4 0 0,1 13,8M17,18V20H1V18C1,15.79 4.58,14 9,14C13.42,14 17,15.79 17,18M20.5,14.5V16H19V14.5H20.5M18.5,9.5H17V9A3,3 0 0,1 20,6A3,3 0 0,1 23,9C23,9.97 22.5,10.88 21.71,11.41L21.41,11.6C20.84,12 20.5,12.61 20.5,13.3V13.5H19V13.3C19,12.11 19.6,11 20.59,10.35L20.88,10.16C21.27,9.9 21.5,9.47 21.5,9A1.5,1.5 0 0,0 20,7.5A1.5,1.5 0 0,0 18.5,9V9.5Z" />
                        </svg>
                        <p>Contactar</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

