import { useContext, useEffect, useState } from "react"

// Context
import VehicleContext from "../../../../context/vehicles/VehicleContext"
import Vehicle from "../../../../interfaces/vehicle/Vehicle"
import { Alert } from "../../../globals/alert/Alert"

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import './Report1.css'
import { initialAlertValues } from "../../../../interfaces/alert/Alert"
import Report1Context from "../../../../context/reports/report1/Report1Context"

export default function Report1() {

    const { vehicles } = useContext(VehicleContext)
    const { report, nextReport } = useContext(Report1Context)

    let initialValue = 0
    let vehicleSelecteds: any = []

    const [reportData, setReportData] = useState<any>([])

    const [allVehicles, setAllVehicles] = useState<any>([])
    const [vehicle, setVehicle] = useState<any>([])

    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    useEffect(() => {
        setReportData(report)
        setAllVehicles(vehicles)
    }, [vehicles, report])

    async function handleSelectVehicle(event: any) {
        let indx = 0
        let vehicleSelcted: Vehicle
        const { value } = event.target

        if (!vehicleSelecteds.length) {
            vehicleSelecteds.push({vehicle: value })
        } else {
            initialValue++
            vehicleSelecteds.push({vehicle: value })
        }

        for (let i = 0; i < vehicles.length; i++) {
            const vehicle: any = vehicles[i];
            if (vehicle['brand'].indexOf(value) > -1) {
                vehicleSelcted = allVehicles[indx]
                allVehicles.splice(indx, 1)
            }
            indx++
        }

        // setVehicle((prevState: any) => [...prevState, { id: initialValue, vehicle: vehicleSelcted }])
        setVehicle((prevState: any) => [...prevState, { vehicle: vehicleSelcted }])
    }

    function removeVehicle(index: number) {
        let oldValue: Vehicle = vehicle[index]['vehicle']
        vehicle.splice(index, 1)
        setVehicle((prevState: any) => [...prevState])
        setAllVehicles((prevState: any) => [...prevState, oldValue])
    }

    function validates () {
        
        if (!vehicle.length) {
            return toast('Debe seleccionar mínimo 1 vehículo para poder continuar', {
                type: 'warning'
            })
        }

        setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Continuar', text: ('¿Desea continuar al siguiente reporte?') })
    }

    function generateData() {
        let newData: any = []
        newData = report
        newData.report = 'third'
        newData.vehicles = vehicle
        
        handleSubmit(newData)
    }

    function handleSubmit (newData: Object[]) {
        try {
            nextReport(newData)
            // Falta direccionamiento
        } catch (error) {
            console.log(error);
            return toast('Hubo algún problema y no se pudo avanzar al siguiente reporte, verifique que los datos sean los correctos!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page">
            <div className="blank-space" />
            <div className="report-1">
                <h4>Reporte 1</h4>
                <p>Seleccione el vehículo que ocupará su cuadrilla</p>

                <div className="vehicles-container">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="data">
                            <div className="input-container">
                                <select multiple className="multi-select" name="laborArea" value={vehicle} onChange={(event) => handleSelectVehicle(event)} >
                                    {
                                        allVehicles.length ? (
                                            allVehicles.map((vehicle: any, index: number) =>
                                                <option key={index} value={vehicle['brand']}>{vehicle['brand']} - {vehicle['model']}</option>
                                            )
                                        ) : (<option disabled>No hay elementos disponibles</option>)
                                    }
                                </select>
                            </div>
                            <div className="vehicles-selecteds">
                                {
                                    vehicle.map((vehicle: any, index: number) =>
                                        <div className="vehicle-selected" key={index}>
                                            <p>{vehicle['vehicle']['brand']}</p>
                                            <button type="button" className="remove-quadrille" onClick={() => removeVehicle(index)}>
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <button className="btn-continue" onClick={validates}>
                            Continuar
                        </button>
                    </form>
                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={generateData} />
        </div>
    )
}