import { createContext } from "react";

// Interfaces
import Employee from "../../interfaces/employee/Employee";
import EmployeeData from "../../interfaces/employee/EmployeeData";

const EmployeesContext = createContext({
    employees: [],
    employeeSelected: [],
    showEmployeeSelected: (employee: any) => {},
    createEmployee: (employee: Employee) => {},
    editEmployee: (employee: EmployeeData) => {},
    deleteEmployee: (employee: EmployeeData) => {},
})

export default EmployeesContext