import { useContext, useEffect, useRef, useState } from "react";

// Context
import ProductsContext from "../../../../../context/products/ProductsContext";

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from "../../../../globals/alert/Alert";
import './Create.css'

// Interfaces
import CreateProductModal from '../../../../../interfaces/product/ProductModal'
import { initialAlertDataValues } from "../../../../../interfaces/alert/Alert";


const CreateProductsModal: React.FC<CreateProductModal> = ({ isOpen, onClose, children }) => {

    const { categories, getCategories, createProducts } = useContext(ProductsContext)

    const date = new Date()

    // Product
    const imageProductRef = useRef<HTMLInputElement>(null)
    const [productBSelectedBase64, setProductBSelectedBase64] = useState('')
    // const [productImageSelected, setProductImageSelected] = useState()

    // Codebar
    // const imageCodebarRef = useRef<HTMLInputElement>(null)
    // const [imageCBSelectedBase64, setCBSelectedBase64] = useState('')
    // const [imageCBSelected, setImageCBSelected] = useState()

    // Code QR
    // const imageCodeQRRef = useRef<HTMLInputElement>(null)
    // const [imageCQRSelectedBase64, setCQRSelectedBase64] = useState('')
    // const [imageCQRSelected, setImageCQRSelected] = useState()

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    const initialValues = {
        name: '',
        SKU: '',
        createdAt: date.getTime() || 0,
        barcode: '',
        price: '',
        stock: '',
        image: '',
        category: ''
    }

    const [product, SetProduct] = useState(initialValues)

    useEffect(() => {
        if (!categories.length) {
            return getCategories()
        }
    }, [categories])

    function handleInputCharged(event: any) {
        const { name, value } = event.target
        SetProduct({ ...product, [name]: value })
    }

    function selectImage(event: any, type: string) {
        if (event.target.files[0]) {
            
            // if (type === 'codebar') {
            //     setImageCBSelected(event.target.files[0])
            // } else {
            //     setImageCQRSelected(event.target.files[0])
            // }
            // Load image selected in image input
            const reader = new FileReader();
            reader.onload = function (e) {
                // The file's text will be printed here
                setProductBSelectedBase64(e.target?.result as string)
                // if (type === 'codebar') {
                //     setCBSelectedBase64(e.target?.result as string)
                // } else {
                //     setCQRSelectedBase64(e.target?.result as string)
                // }
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    function validations() {
        if (!product.name) {
            return toast('El campo nombre no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!product.SKU) {
            return toast('El campo SKU no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!product.stock) {
            return toast('El campo stock no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!product.barcode) {
            return toast('El campo código de barras no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!product.category) {
            return toast('El campo categoria no puede estar vacio', {
                type: 'warning'
            })
        }

        setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Agregar empleado', text: ('¿Desea agregar al empleado ' + product['name'].toLocaleLowerCase() + '?') })
    }

    function handleSubmit () {
        try {
            createProducts(product, productBSelectedBase64)
            toast('Producto registrado con éxito', {
                type: 'success'
            })
            setProductBSelectedBase64('')
            SetProduct(initialValues)
            onClose()
        } catch (error) {
            toast('Ocurrió un problema y no se pudo registrar el producto!', {
                type: 'error'
            })
        }
    }

    return isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Crear producto</h5>

                    <button className="btn-close-modal" onClick={onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>

                <div className="modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="name"
                                required
                                value={product.name}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Nombre</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H11V19.13L19.39 10.74C19.83 10.3 20.39 10.06 21 10M14 4.5L19.5 10H14V4.5M22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19M19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83Z" />
                            </svg>
                            <input
                                type="text"
                                name="SKU"
                                required
                                value={product.SKU}
                                autoComplete="off"
                                autoSave="off"
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>SKU</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
                            </svg>
                            <input
                                type="number"
                                name="price"
                                required
                                value={product.price}
                                autoComplete="off"
                                autoSave="off"
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Precio</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" />
                            </svg>
                            <input
                                type="number"
                                name="stock"
                                required
                                value={product.stock}
                                autoComplete="off"
                                autoSave="off"
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>STOCK</span>
                        </div>
                        <div className="input-container">
                            <select className="select" name="category" value={product.category} onChange={(event) => SetProduct({ ...product, category: event.target.value })}>
                            <option disabled  value={''}>Seleccione una categoria</option>
                                {
                                    categories.map((category, index) => 
                                        <option key={index} value={category['name']}>{category['name']}</option>
                                    )
                                }
                            </select>
                        </div>
                        <p className="barcode-text">Agregar el código de barras de forma manual.</p>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z" />
                            </svg>
                            <input
                                type="text"
                                name="barcode"
                                required
                                value={product.barcode}
                                autoComplete="off"
                                autoSave="off"
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Código de barras</span>
                        </div>
                        <div className="image-selected-container display-flex">
                            <div>
                                <input ref={imageProductRef} accept="image/*" type="file" hidden onChange={(event) => selectImage(event, 'codebar')} />
                                <button type="button" className="btn-image" onClick={() => imageProductRef.current?.click()}>
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z" />
                                    </svg>
                                </button>
                                {
                                    productBSelectedBase64 ? (
                                        <button type="button" className="btn-clear-image" onClick={() => setProductBSelectedBase64('')}>
                                            <svg className="svg-icon" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                            </svg>
                                        </button>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="image-selected">
                                <p>Imágen del producto:</p>
                                {
                                    productBSelectedBase64 ? (
                                        <img src={productBSelectedBase64} alt="" />
                                    ) : (<p className="no-image">Ninguno seleccionado.</p>)
                                }
                            </div>
                        </div>
                        {/* <div className="image-selected-container display-flex">
                            <div>
                                <input ref={imageCodebarRef} accept="image/*" type="file" hidden onChange={(event) => selectImage(event, 'codebar')} />
                                <button type="button" className="btn-image" onClick={() => imageCodebarRef.current?.click()}>
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z" />
                                    </svg>
                                </button>
                                {
                                    imageCBSelectedBase64 ? (
                                        <button type="button" className="btn-clear-image" onClick={() => setCBSelectedBase64('')}>
                                            <svg className="svg-icon" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                            </svg>
                                        </button>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="image-selected">
                                <p>Imágen de código de barras seleccionada:</p>
                                {
                                    imageCBSelectedBase64 ? (
                                        <img src={imageCBSelectedBase64} alt="" />
                                    ) : (<p className="no-image">Ninguna.</p>)
                                }
                            </div>
                        </div>
                        <div className="image-selected-container display-flex">
                            <div>
                                <input ref={imageCodeQRRef} accept="image/*" type="file" hidden onChange={(event) => selectImage(event, 'QR')} />
                                <button type="button" className="btn-image" onClick={() => imageCodeQRRef.current?.click()}>
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z" />
                                    </svg>
                                </button>
                                {
                                    imageCQRSelectedBase64 ? (
                                        <button type="button" className="btn-clear-image" onClick={() => setCQRSelectedBase64('')}>
                                            <svg className="svg-icon" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                            </svg>
                                        </button>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="image-selected">
                                <p>Imágen de código QR seleccionada:</p>
                                {

                                    imageCQRSelectedBase64 ? (
                                        <img src={imageCQRSelectedBase64} alt="" />
                                    ) : (<p className="no-image">Ninguna.</p>)
                                }
                            </div>
                        </div> */}
                        <button className="btn-submit" type="submit" onClick={validations}>
                            Crear
                        </button>
                    </form>
                </div>
            </div>

            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertDataValues, alertOpen: !alertOpen.alertOpen})} accept={handleSubmit} />
        </div>
    ) : null
}

export default CreateProductsModal