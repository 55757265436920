// React
import React, { useContext, useRef, useState } from 'react'

// Libraries
import { toast } from 'react-toastify'

//Context
import PromotionContext from '../../../../../../context/promotions/PromotionContext'

// Components and CSS
import { Alert } from '../../../../../globals/alert/Alert'
import { DatepickerRangeOfDates } from '../../../../../globals/datepicker/rangeOfDates/Datepicker'
import './Create.css'

interface ModalProps {
    isOpen: boolean,
    // alertResponse: (type: string) => void,
    onClose: () => void
}

export const CreateImageHomeModal: React.FC<ModalProps> = ({ isOpen, onClose, /*alertResponse,*/ children }) => {
    const initialValues = {
        id: '',
        title: '',
        mainDescription: '',
        extraInformation1: '',
        extraInformation2: '',
        initialDate: {
            day: '',
            month: '',
            year: 0
        },
        endDate: {
            day: '',
            month: '',
            year: 0
        },
        createdAt: new Date().getTime()
    }
    const initialAlertValues = {
        alertOpen: false,
        text: '',
        title: '',
        image: false
    }
    // Context
    const { addPromotion, storage } = useContext(PromotionContext)

    // Variables to create Promotion
    const modalRef = useRef(null)
    const [dataPromotion, setDataPromotion] = useState(initialValues)
    const imageRef = useRef<HTMLInputElement>(null)
    const [imageSelected, setImageSelected] = useState()
    const [imageSelectedBase64, setImageSelectedBase64] = useState('')

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    // Datepicker Props
    const [datepickerOpen, setDatepickerOpen] = useState<boolean>(false)

    // Form functions
    function handleInputCharged(event: any) {
        const { name, value } = event.target
        setDataPromotion({ ...dataPromotion, [name]: value })
    }
    function selectImage(event: any) {
        if (event.target.files[0]) {
            setImageSelected(event.target.files[0])
            // Load image selected in image input
            const reader = new FileReader();
            reader.onload = function (e) {
                // The file's text will be printed here
                setImageSelectedBase64(e.target?.result as string)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    function validations() {
        if (!dataPromotion.title) {
            return toast('El campo título no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!dataPromotion.mainDescription) {
            return toast('El campo descripción no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!dataPromotion.extraInformation1) {
            return toast('El campo información extra 1 no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!dataPromotion.extraInformation2) {
            return toast('El campo información extra 2 no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!dataPromotion.initialDate.day && !dataPromotion.initialDate.month && !dataPromotion.initialDate.year ||
            !dataPromotion.endDate.day && !dataPromotion.endDate.month && !dataPromotion.endDate.year) {
            return toast('Debe seleccionar un rango de fechas correctas', {
                type: 'warning'
            })
        }
        if (imageSelectedBase64) {
            return setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Crear promoción', text: ('¿Desea crear la promoción ' + dataPromotion['title'].toLocaleLowerCase() + '?'), image: true })
        }
        setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Crear promoción', text: ('¿Desea crear la promoción ' + dataPromotion['title'].toLocaleLowerCase() + '?') })
    }

    async function handleSubmit() {
        try {
            if (imageSelected) {
                storage(imageSelected)
                addPromotion(dataPromotion, imageSelectedBase64)
            } else {
                addPromotion(dataPromotion, imageSelectedBase64)
            }
            toast('Promoción agregada a tu página web!', {
                type: 'success'
            })
        } catch (error) {
            console.log(error);
            toast('Hubo un problema al crear la promoción!', {
                type: 'error'
            })
        }
        setImageSelectedBase64('')
        setDataPromotion(initialValues)
    }

    return isOpen ? (
        <div ref={modalRef} className="modal">
            <div className="modal-content">

                <div className="modal-header">
                    <button className="btn-close-modal" onClick={onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>

                    <h5>Agregar promoción</h5>
                </div>

                <div className="modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="title"
                                name="title"
                                required
                                value={dataPromotion.title}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Título</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M22 13H14V11H22V13M22 7H14V9H22V7M14 17H22V15H14V17M12 9V15C12 16.1 11.1 17 10 17H4C2.9 17 2 16.1 2 15V9C2 7.9 2.9 7 4 7H10C11.1 7 12 7.9 12 9M10.5 15L8.3 12L6.5 14.3L5.3 12.8L3.5 15H10.5Z" />
                            </svg>
                            <textarea
                                rows={5}
                                name="mainDescription"
                                required
                                value={dataPromotion.mainDescription}
                                autoComplete="off"
                                autoSave="off"
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Descripción principal</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4,9H20V11H4V9M4,13H14V15H4V13Z" />
                            </svg>
                            <input
                                type="text"
                                name="extraInformation1"
                                required
                                value={dataPromotion.extraInformation1}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Información extra 1</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M4,9H20V11H4V9M4,13H14V15H4V13Z" />
                            </svg>
                            <input
                                type="text"
                                name="extraInformation2"
                                required
                                value={dataPromotion.extraInformation2}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleInputCharged(event)}
                            />
                            <span>Información extra 2</span>
                        </div>
                        <div className="image-selected-container display-flex">
                            <div>
                                <input ref={imageRef} accept="image/*" type="file" hidden onChange={selectImage} />
                                <button type="button" className="btn-image" onClick={() => imageRef.current?.click()}>
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z" />
                                    </svg>
                                </button>
                                {
                                    imageSelectedBase64 ? (
                                        <button type="button" className="btn-clear-image" onClick={() => setImageSelectedBase64('')}>
                                            <svg className="svg-icon" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                            </svg>
                                        </button>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="image-selected">
                                <p>Imágen seleccionada:</p>
                                {
                                    imageSelectedBase64 ? (
                                        <img src={imageSelectedBase64} alt="" />
                                    ) : (<p className="no-image">Ninguna.</p>)
                                }
                            </div>
                        </div>
                        <div className="date-container">
                            <button className="btn-date" type="button" onClick={() => setDatepickerOpen(!datepickerOpen)}>
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M9,10H7V12H9V10M13,10H11V12H13V10M17,10H15V12H17V10M19,3H18V1H16V3H8V1H6V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,19H5V8H19V19Z" />
                                </svg>
                            </button>
                            <div className="date-info">
                                <div className="initial-date">
                                    <p>Inicio de la promoción</p>
                                    {
                                        dataPromotion.initialDate['day'] ? (
                                            <p>{dataPromotion.initialDate['day']}/
                                                {dataPromotion.initialDate['month']}/
                                                {dataPromotion.initialDate['year']}</p>
                                        ) : (
                                            <p className="no-date">No ha seleccionado ninguna fecha</p>
                                        )
                                    }
                                </div>
                                <div className="end-date">
                                    <p>Fin de la promoción</p>
                                    {
                                        dataPromotion.endDate['day'] ? (
                                            <p>{dataPromotion.endDate['day']}/
                                                {dataPromotion.endDate['month']}/
                                                {dataPromotion.endDate['year']}</p>
                                        ) : (
                                            <p className="no-date">No ha seleccionado ninguna fecha</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <DatepickerRangeOfDates isOpen={datepickerOpen} onClose={() => setDatepickerOpen(!datepickerOpen)} datepickerData={(datepicker) => setDataPromotion({
                            ...dataPromotion, id: dataPromotion.id,
                            title: dataPromotion.title,
                            mainDescription: dataPromotion.mainDescription,
                            extraInformation1: dataPromotion.extraInformation1,
                            extraInformation2: dataPromotion.extraInformation2,
                            initialDate: {
                                day: datepicker.initialDate.day,
                                month: datepicker.initialDate.month,
                                year: datepicker.initialDate.year
                            },
                            endDate: {
                                day: datepicker.endDate.day,
                                month: datepicker.endDate.month,
                                year: datepicker.endDate.year
                            },
                            createdAt: new Date().getTime()
                        })} />
                        <button className="btn-submit" type="submit" onClick={validations}>
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertValues, alertOpen: !alertOpen.alertOpen })} accept={handleSubmit} />
        </div>
    ) : null
}