import { createContext } from 'react'

const LandingPage = createContext({
    // Home Page
    imagesHome: [],
    getImagesHome: () => {},

    // Promotions
    promotions: [],
    getPromotions: () => {},

    // Services Page
    services: [],
    getServices: () => {},

    // All pages
    handleAddingStyles: () => {},
    redirect: (page: string) => {},
    goToPage: (page: string) => {},
    showNotification: () => {},
})

export default LandingPage

