
export const initialAlertValues = {
    alertOpen: false,
    text: '',
    title: '',
    image: false
}
export const initialAlertDataValues = {
    alertOpen: false,
    text: '',
    title: '',
    image: false,
    data: {}
}

interface AlertInterface {
    alertIsOpen: boolean,
    data: any,
    onClose: () => void,
    accept: () => void
}

export default AlertInterface