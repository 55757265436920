import { useContext, useEffect, useState } from 'react'

//Context
import LandingPageContext from '../../context/landingPage/LandingPageContext'

// Components and CSS
import Loader from '../../components/globals/loader/Loader'
import NavbarHome from '../../components/webSite/navbar/NavbarHome'
import ContactForm from '../../components/webSite/contactForm/contactForm'
import FooterWS from '../../components/webSite/footer/FooterWS'
import { PromotionsModal } from '../../components/webSite/promotions/Promotions'
import './Home.css'

export default function Home() {
    // Context
    const { imagesHome, getImagesHome, redirect } = useContext(LandingPageContext)
    const clasesImg = ['left', 'mid', 'right']

    // Promotion Modal Props
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    useEffect(() => {
        if (!imagesHome.length) {
            getImagesHome()
        }
    }, [imagesHome])

    function goToContactForm() {
        const contactForm = document.querySelector('.contact')
        contactForm?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    return (
        <div className="home">
            <Loader />

            <NavbarHome />

            <div className="home-header">
                <div className="text">
                    <div className="main-text">
                        <h1 className="display-1">Seguridad y confianza</h1>
                        <p className="display-4">en tu hogar, negocio, patrimonio</p>
                        <button className="btn-contact" onClick={goToContactForm}>
                            Contactar
                        </button>
                    </div>

                </div>

                <div className="image-container">
                    {
                        imagesHome.length ? (
                            imagesHome.map((imageHome, index) =>
                                <div key={index}>
                                    <img className={index < 3 ? clasesImg[index] : 'mid'} src={imageHome['urlImage']} alt="" />
                                </div>
                            )
                        ) : (
                                <div className="skeleton">
                                    <div className="skeleton-image skeleton-left"></div>
                                    <div className="skeleton-image skeleton-mid"></div>
                                    <div className="skeleton-image skeleton-right"></div>
                                </div>
                            )
                    }

                </div>

            </div>
            <div className="home-body">
                <div className="companies">
                    <img className="logo-company" src={process.env.PUBLIC_URL + '/assets/company/CTonline.png'} alt="Logo CTonline" />
                    <img className="logo-company" src={process.env.PUBLIC_URL + '/assets/company/fibremex.png'} alt="Logo Fibremex" />
                    <img className="logo-company" src={process.env.PUBLIC_URL + '/assets/company/Ingram micro.png'} alt="Logo Ingram Micro" />
                    <img className="logo-company" src={process.env.PUBLIC_URL + '/assets/company/Luguer-Nuevo.jpg'} alt="Logo Luguer Nuevo" />
                    <img className="logo-company" src={process.env.PUBLIC_URL + '/assets/company/Syscom.png'} alt="Logo Syscom" />
                    <img className="logo-company" src={process.env.PUBLIC_URL + '/assets/company/TVCenlinea.png'} alt="Logo TVCenlinea" />
                </div>

                <div className="about-us">
                    <div className="text">
                        <div className="text-data">
                            <p className="title">¿Quienes somos?</p>
                            <p className="text-website">
                                Empresa 100% Lagunera especializada en el ramo de
                                telecomunicaciones, redes, telefonía y circuito cerrado;
                                enfocada en brindar sus servicios con la mejor calidad
                                para sus clientes.
                            </p>
                        </div>
                    </div>
                    <div className="image-container">
                        <img className="logo-image" src={process.env.PUBLIC_URL + '/assets/Logo.png'} alt="Logo Arpi Technology" />
                    </div>
                </div>

                <div className="services">
                    <p className="title">Servicios</p>
                    <div className="services-cards-container grid col-3 mid-col-2 small-col-1">
                        <div className="card">
                            <p className="">
                                Radiocomunicación.
                            </p>
                            <p>
                                La radiocomunicación es una forma de telecomunicación que se realiza a través de ondas de radio u ondas hertzianas, la...
                            </p>
                        </div>
                        <div className="card">
                            <p className="">
                                CCTV (sistemas analógicos IP/digital)
                            </p>
                            <p>
                                CCTV consiste en una o más cámaras de vigilancias conectadas a uno o más monitores de video o televisores que reproducen...
                            </p>
                        </div>
                        <div className="card">
                            <p className="">
                                Control de acceso (peatonal/vehicular)
                            </p>
                            <p>
                                Un control de acceso es un sistema automatizado que permite de forma eficaz, aprobar o negar el paso de personas, grupo de personas o...
                            </p>
                        </div>
                    </div>
                    <button onClick={() => redirect('/services')}>
                        Ver más
                    </button>
                </div>

                <ContactForm />
            </div>

            <button className="btn-promotion" onClick={() => setModalOpen(!modalOpen)}>
                <svg className="svg-icon" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z" />
                </svg>
            </button>

            <PromotionsModal isOpen={modalOpen} onClose={() => setModalOpen(!modalOpen)} />
            <FooterWS />
        </div>
    )
}