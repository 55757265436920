import React, { useState } from 'react'
import 'firebase/storage'

// Firestore
import firebase from '../../services/firebase/Config'

// Context
import ServiceWSContext from './ServiceWSContext'

const firestore = firebase.firestore()
const storageRef = firebase.storage().ref('Website')

interface ServiceWS {
    id: string,
    title: string,
    description: string
}

const ImageHome: React.FC = (props: any) => {
    const [services, setServices] = useState([])

    async function addService(service: ServiceWS, image: string) {
        return await firestore.collection('ServicesWS').doc().set({
            title: service.title,
            description: service.description,
            urlImage: image
        })
    }

    function getServices () {
        firestore.collection('ServicesWS').onSnapshot(response => {
            const docs: any = []
            response.forEach(serviceWS => {
                docs.push({ ...serviceWS.data(), id: serviceWS.id })
            })
            setServices(docs)
        })
    }

    async function editService(service: ServiceWS, image: any) {
        return await firestore.collection('ServicesWS').doc(service.id).set({
            title: service.title,
            description: service.description,
            urlImage: image
        })
    }

    async function deleteService(serviceId: string) {
        return await firestore.collection('ServicesWS').doc(serviceId).delete()
    }

    async function storage(image: any) {
        await storageRef.child('/ServicesWS/' + image.name).put(image)
    }

    const value = {
        services, getServices, addService, editService, deleteService, storage
    }

    return (
        <ServiceWSContext.Provider value={value}>
            {props.children}
        </ServiceWSContext.Provider>
    )
}

export default ImageHome