export const initialValues = {
    open: false,
    data: {}
}

interface ModalData {
    isOpen: {
        open: boolean,
        data: any
    },
    onClose: () => void
}

export default ModalData