import { useEffect, useState } from "react"

// Firebase
import firebase from '../../services/firebase/Config'

// Context
import ProductsContext from "./ProductsContext"

// Interfaces
import Product from '../../interfaces/product/Product'
import EditProduct from '../../interfaces/product/EditProduct'

const Products: React.FC = (props: any) => {

    const firestore = firebase.firestore()

    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])
    const [productSelected, setProductSelected] = useState([])

    useEffect(() => {
        getProducts()
    }, [])

    function getCategories() {
        firestore.collection('categories').get().then(snap => {
            var docs: any = []
            snap.forEach(category => {
                docs.push(category.data())
            })
            setCategories(docs)
        })
    }

    function getProducts() {
        firestore.collection('products').onSnapshot(snap => {
            var docs: any = []
            snap.forEach(product => {
                docs.push({ ...product.data(), id: product.id })
            })
            setProducts(docs)
        })
    }

    function showProductSelected (product: Product) {
        if (Object.values(product).length === products.length) {
            return setProductSelected([])
        }
        
        let docs: any = []
        docs.push(product)
        return setProductSelected(docs)
    }

    async function createProducts (product: Product, image: string) {
        return await firestore.collection('products').doc().set({
            name: product.name,
            SKU: product.SKU,
            createdAt: product.createdAt,
            barcode: product.barcode,
            price: product.price,
            stock: product.stock,
            image: image,
            category: product.category
        })
    }

    async function addProducts (product: EditProduct, stock: number) {
        return await firestore.collection('products').doc(product.id).set({
            name: product.name,
            SKU: product.SKU,
            createdAt: product.createdAt,
            barcode: product.barcode,
            price: product.price,
            stock: (parseInt(product.stock) + stock),
            image: product.image,
            category: product.category
        })
    }

    async function editProducts (product: EditProduct, image: string) {
        return await firestore.collection('products').doc(product.id).set({
            name: product.name,
            SKU: product.SKU,
            createdAt: product.createdAt,
            barcode: product.barcode,
            price: product.price,
            stock: product.stock,
            image: image,
            category: product.category
        })
    }

    async function deleteProducts (productId: string) {
        return await firestore.collection('products').doc(productId).delete()
    }

    const value = {
        // Categories
        categories, getCategories,
        // Products
        products, productSelected, showProductSelected, createProducts, addProducts, editProducts, deleteProducts
    }

    return (
        <ProductsContext.Provider value={value}>
            {props.children}
        </ProductsContext.Provider>
    )
}

export default Products