
// Context
import Report1Context from "./Report1Context"

// Firebase
import firebase from '../../../services/firebase/Config'
import { useEffect, useState } from "react"

const Report1: React.FC = (props) => {

    const firestore = firebase.firestore()

    const date = new Date()
    const today = (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear())

    const [report, setReport] = useState([])

    useEffect(() => {
        getReport()
    }, [today])

    async function getReport() {
        let docs: any = []
        return await firestore.collection('reports').doc(today).get().then(snap => {
            docs.push(snap.data())
            setReport(docs[0])
        })
    }

    async function nextReport (report: Object[]) {
        return await firestore.collection('reports').doc(today).update(report)
    }

    const value = {
        report, nextReport
    }

    return <Report1Context.Provider value={value}>
        {props.children}
    </Report1Context.Provider>
}

export default Report1