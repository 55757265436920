import { useContext, useEffect, useState } from "react";

// Context
import LandingPage from "../../context/landingPage/LandingPageContext";

// Components and CSS
import ContactForm from "../../components/webSite/contactForm/contactForm";
import FooterWS from "../../components/webSite/footer/FooterWS";
import NavbarHome from "../../components/webSite/navbar/NavbarHome";
import Loader from "../../components/globals/loader/Loader";
import { ModalServices } from "./modal/ModalServices";
import { PromotionsModal } from "../../components/webSite/promotions/Promotions";
import './ServicesWS.css'
import './modal/ModalServices.css'

// Interfaces
interface Service {
    title: string,
    description: string,
    urlImage: string
}

export default function ServicesWS() {
    const { services, getServices } = useContext(LandingPage)
    const [serviceSelected, setServiceSelected] = useState<Service>()

    // Open Modal Services
    const [modalOpenServices, setModalOpenServices] = useState<boolean>(false)

    // Promotion Modal Props
    const [modalOpenPromotions, setModalOpenPromotions] = useState<boolean>(false)

    useEffect(() => {
        if (!services.length) {
            getServices()
        }
    }, [services])


    function getAndShowService(service: Service) {
        setServiceSelected(service)
        setModalOpenServices(!modalOpenServices)
    }

    return (
        <div className="services-home">
            <Loader />

            <NavbarHome />

            <div className="services-header">
                <p className="title">Servicios</p>
                <p>
                    Contamos con un repertorio de servicios con certificados Panduit, Seguridad y Cableado estructurado, brindando seguridad a la Comarca Lagunera desde hace más de 20 años.
                </p>
            </div>

            <div className="services-body">

                <div className="services-cards-container grid col-3 mid-col-2 small-col-1">
                    {
                        services.length ? (
                            services.map((service, index) =>
                                <div className="card" key={index}>
                                    <img src={service['urlImage']} alt="" />
                                    <p>{service['title']}</p>
                                    <p onClick={() => getAndShowService(service)}>Ver
                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                        </svg>
                                    </p>
                                </div>
                            )
                        ) : (<></>)
                    }

                </div>

                <ContactForm />
            </div>

            <button className="btn-promotion" onClick={() => setModalOpenPromotions(!modalOpenPromotions)}>
                <svg className="svg-icon" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z" />
                </svg>
            </button>

            <PromotionsModal isOpen={modalOpenPromotions} onClose={() => setModalOpenPromotions(!modalOpenPromotions)} />

            <FooterWS />

            <ModalServices isOpen={modalOpenServices} data={serviceSelected} onClose={() => setModalOpenServices(!modalOpenServices)} />
        </div>
    )
}