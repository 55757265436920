import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../../context/auth/AuthContext'

// Components and CSS
import './SignIn.css'

export default function SignIn() {
    const { user, signin } = useContext(AuthContext)

    const [dataUser, setDataUser] = useState({
        email: '',
        password: ''
    })

    // Alerts
    const [error, setError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const history = useHistory()

    useEffect(() => {
        if (user) {
            history.push('/admin')
        }
    }, [user])

    function handleInputCharged(event: any) {
        const { name, value } = event.target
        setDataUser({ ...dataUser, [name]: value })
    }

    async function handleSubmit () {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        setLoading(true)

        if (!dataUser.email.length){
            setLoading(false)
            return setError('El campo email no puede estar vacío')
        }
        if (!dataUser.password.length) {
            setLoading(false)
            return setError('El campo contraseña no puede estar vacío')
        }

        if (!pattern.test(dataUser.email)) {
            setLoading(false)
            return setError('Ingrese un correo electrónico válido')
        }

        setError('')
            
        try {
            await signin(dataUser.email, dataUser.password)

            if (user) {
                history.push('/admin')
            }
        } catch (error) {
            console.log(error);
            switch (error.code) {
                case 'auth/user-not-found':
                    setError('Usuario no registrado')
                    break;
                case 'auth/wrong-password':
                    setError('Contraseña incorrecta')
                    break;
                case 'auth/invalid-email':
                    setError('Correo incorrecto')
                    break;
                case 'auth/too-many-requests':
                    setError('Se bloqueó su acceso de manera temporal ya que se realizaron varias peticiones con contraseña incorrecta a su correo, vuelva a intentarlo después')
                    break;
                default:
                    setError('No se pudo iniciar sesión')
                    break;
            }
            setLoading(false)
        }
    }

    return (
        <div className="sign-in">
            <main className="grid col-2 -med-col-2 small-col-1">
                <form className="container" onSubmit={(event) => event.preventDefault()}>
                    <h2>Iniciar de sesión</h2>
                    <p>Inicie sesión con la cuenta que le proporcionó <strong>Arpi Technology</strong></p>

                    {
                        error ? (
                            <div className="error">{error}</div>
                        ) : (<></>)
                    }

                    <div className="input-container">
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
                        </svg>
                        <input 
                            type="email"
                            name="email"
                            required
                            autoComplete="off"
                            autoSave="off"
                            autoFocus={true}
                            onChange={ (event) => handleInputCharged(event) } 
                        />
                        <span>Correo electrónico</span>
                    </div>
                    <div className="input-container">
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                        </svg>
                        <input
                            type="password"
                            name="password"
                            required
                            autoComplete="off"
                            autoSave="off"
                            onChange={ (event) => handleInputCharged(event) } />
                        <span>Contraseña</span>
                    </div>
                    <div className="checkbox-container">
                        <label className="container">Mantenerme conectado
                            <input
                                type="checkbox"
                                className="checked"
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <button className="btn-submit" onClick={handleSubmit}>
                        Acceder
                    </button>
                </form>
                <div className="image-container">
                    <img className="logo-image" src={process.env.PUBLIC_URL + '/assets/Logo.png'} alt="Logo Arpi Technology" />
                </div>
            </main>
        </div>
    )
}