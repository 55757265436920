import { createContext } from 'react'

interface PromotionWS {
    id: string,
    title: string,
    mainDescription: string,
    extraInformation1: string,
    extraInformation2: string,
    initialDate: {
        day: string,
        month: string,
        year: number
    },
    endDate: {
        day: string,
        month: string,
        year: number
    },
    createdAt: number
}

const PromotionContext = createContext({
    promotions: [],
    addPromotion: (promotion: PromotionWS, image: string) => {},
    editPromotion: (promotion: PromotionWS, image: string) => {},
    deletePromotion: (promotionId: string) => {},
    storage: (image: any) => {}
})

export default PromotionContext