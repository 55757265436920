import { useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router';
import AuthContext from '../../../context/auth/AuthContext'
import './Navbar.css'

export default function Navbar() {

    const { user, logout } = useContext(AuthContext)

    const ulRef = useRef<HTMLUListElement>(null)
    const navbarLeftRef = useRef<HTMLDivElement>(null)

    const history = useHistory()

    useEffect(() => {
        if (!user) {
            history.push('/admin/signin')
        }
    })

    function handleChangeStylesNavbarLeft() {
        const dom = document.querySelector('.grid-admin-page')
        if (dom?.classList.contains('active')) {
            dom.classList.remove('active')
            setTimeout(() => {
                navbarLeftRef.current?.classList.add('active')
            }, 200);
        } else {
            navbarLeftRef.current?.classList.remove('active')
            setTimeout(() => {
                dom?.classList.add('active');
            }, 300);
        }
    }

    function handleChangeStyles() {
        if (ulRef.current?.classList.contains('active')) {
            ulRef.current.classList.remove('active')
            ulRef.current.style.opacity = '0'
        } else {
            ulRef.current?.classList.add('active');
            ulRef.current!.style.opacity = '1'
            ulRef.current!.style.visibility = 'visible'
        }
    }
    deleteReportData()
    function deleteReportData() {
        if (history.location.pathname != '/admin/report-0') {
            localStorage.removeItem('nextForm')
            localStorage.removeItem('nextFormEdit')
        }
    }

    return (
        <div className="navbar">
            <div className="navbar-top">
                <button className="btn-toogle-navbar" onClick={handleChangeStylesNavbarLeft}>
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z" />
                    </svg>
                </button>
                <div className="profile-content">
                    <button className="btn-profile" onClick={handleChangeStyles}>
                        <img className="profile-image" src={process.env.PUBLIC_URL + '/assets/profile/vane.jpeg'} alt="Logo Arpi Technology" />
                    </button>
                    <ul ref={ulRef}>
                        <li>
                            Ajustes
                        </li>
                        <li onClick={logout}>
                            Cerrar sesión
                        </li>
                    </ul>
                </div>
            </div>

            <div ref={navbarLeftRef} className="navbar-left active">
                <div className="navbar-header">
                    <div className="image-container" onClick={() => history.push('/admin')}>
                        <img className="logo-image" src={process.env.PUBLIC_URL + '/assets/Logo.png'} alt="Logo Arpi Technology" />
                    </div>
                </div>
                <hr />
                <div className="navbar-body">
                    <div className="actions">
                        <ul>
                            <li className={history.location.pathname == '/admin/website-images-home' ? 'active' : ''}  onClick={() => history.push('/admin/website-images-home')}>
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/website-promotions' ? 'active' : ''}  onClick={() => history.push('/admin/website-promotions')}>
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/website-services' ? 'active' : ''}  onClick={() => history.push('/admin/website-services')}>
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" />
                                </svg>
                            </li>

                            <hr />

                            <li className={history.location.pathname == '/admin/services' ? 'active' : ''} onClick={() => history.push('/admin/services')}>
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/products' ? 'active' : ''} onClick={() => history.push('/admin/products')} >
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L6.04,7.5L12,10.85L17.96,7.5L12,4.15Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/quadrilles' || history.location.pathname == '/admin/report-0' ? 'active' : ''} onClick={() => history.push('/admin/quadrilles')} >
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,15C7.58,15 4,16.79 4,19V21H20V19C20,16.79 16.42,15 12,15M8,9A4,4 0 0,0 12,13A4,4 0 0,0 16,9M11.5,2C11.2,2 11,2.21 11,2.5V5.5H10V3C10,3 7.75,3.86 7.75,6.75C7.75,6.75 7,6.89 7,8H17C16.95,6.89 16.25,6.75 16.25,6.75C16.25,3.86 14,3 14,3V5.5H13V2.5C13,2.21 12.81,2 12.5,2H11.5Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/report-1' ? 'active' : ''} onClick={() => history.push('/admin/report-1')} >
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M5 6C3.9 6 3 6.9 3 8S3.9 10 5 10 7 9.11 7 8 6.11 6 5 6M12 4C10.9 4 10 4.89 10 6S10.9 8 12 8 14 7.11 14 6 13.11 4 12 4M19 2C17.9 2 17 2.9 17 4S17.9 6 19 6 21 5.11 21 4 20.11 2 19 2M3.5 11C2.67 11 2 11.67 2 12.5V17H3V22H7V17H8V12.5C8 11.67 7.33 11 6.5 11H3.5M10.5 9C9.67 9 9 9.67 9 10.5V15H10V20H14V15H15V10.5C15 9.67 14.33 9 13.5 9H10.5M17.5 7C16.67 7 16 7.67 16 8.5V13H17V18H21V13H22V8.5C22 7.67 21.33 7 20.5 7H17.5Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/employees' ? 'active' : ''} onClick={() => history.push('/admin/employees')} >
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,20 12,20Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/vehicles' ? 'active' : ''} onClick={() => history.push('/admin/vehicles')} >
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M3,6H16L19,10H21C22.11,10 23,10.89 23,12V15H21A3,3 0 0,1 18,18A3,3 0 0,1 15,15H9A3,3 0 0,1 6,18A3,3 0 0,1 3,15H1V8C1,6.89 1.89,6 3,6M2.5,7.5V10H10.5V7.5H2.5M12,7.5V10H17.14L15.25,7.5H12M6,13.5A1.5,1.5 0 0,0 4.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,15A1.5,1.5 0 0,0 6,13.5M18,13.5A1.5,1.5 0 0,0 16.5,15A1.5,1.5 0 0,0 18,16.5A1.5,1.5 0 0,0 19.5,15A1.5,1.5 0 0,0 18,13.5Z" />
                                </svg>
                            </li>
                            <li className={history.location.pathname == '/admin/categories' ? 'active' : ''} onClick={() => history.push('/admin/categories')} >
                                <svg className="svg-icon" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12.6,2.86C15.27,4.1 18,5.39 20.66,6.63C20.81,6.7 21,6.75 21,6.95C21,7.15 20.81,7.19 20.66,7.26C18,8.5 15.3,9.77 12.62,11C12.21,11.21 11.79,11.21 11.38,11C8.69,9.76 6,8.5 3.32,7.25C3.18,7.19 3,7.14 3,6.94C3,6.76 3.18,6.71 3.31,6.65C6,5.39 8.74,4.1 11.44,2.85C11.73,2.72 12.3,2.73 12.6,2.86M12,21.15C11.8,21.15 11.66,21.07 11.38,20.97C8.69,19.73 6,18.47 3.33,17.22C3.19,17.15 3,17.11 3,16.9C3,16.7 3.19,16.66 3.34,16.59C3.78,16.38 4.23,16.17 4.67,15.96C5.12,15.76 5.56,15.76 6,15.97C7.79,16.8 9.57,17.63 11.35,18.46C11.79,18.67 12.23,18.66 12.67,18.46C14.45,17.62 16.23,16.79 18,15.96C18.44,15.76 18.87,15.75 19.29,15.95C19.77,16.16 20.24,16.39 20.71,16.61C20.78,16.64 20.85,16.68 20.91,16.73C21.04,16.83 21.04,17 20.91,17.08C20.83,17.14 20.74,17.19 20.65,17.23C18,18.5 15.33,19.72 12.66,20.95C12.46,21.05 12.19,21.15 12,21.15M12,16.17C11.9,16.17 11.55,16.07 11.36,16C8.68,14.74 6,13.5 3.34,12.24C3.2,12.18 3,12.13 3,11.93C3,11.72 3.2,11.68 3.35,11.61C3.8,11.39 4.25,11.18 4.7,10.97C5.13,10.78 5.56,10.78 6,11C7.78,11.82 9.58,12.66 11.38,13.5C11.79,13.69 12.21,13.69 12.63,13.5C14.43,12.65 16.23,11.81 18.04,10.97C18.45,10.78 18.87,10.78 19.29,10.97C19.76,11.19 20.24,11.41 20.71,11.63C20.77,11.66 20.84,11.69 20.9,11.74C21.04,11.85 21.04,12 20.89,12.12C20.84,12.16 20.77,12.19 20.71,12.22C18,13.5 15.31,14.75 12.61,16C12.42,16.09 12.08,16.17 12,16.17Z" />
                                </svg>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}