// Components and CSS
import { useContext, useRef, useState } from 'react'

// Context
import EmployeesContext from '../../../../../context/employees/EmployeesContext'

// Components and CSS
import './Seeker.css'

// Interfaces
import Employee from '../../../../../interfaces/employee/Employee'

export default function Report0Seeker() {

    const columns = document.querySelectorAll('.column')

    const inputSeekerRef = useRef<HTMLInputElement>(null)
    const ulSeekerRef = useRef<HTMLUListElement>(null)

    const { employees } = useContext(EmployeesContext)
    const [employeeFound, setEmployeeFound] = useState([])

    function filterData(event: string) {
        let dataSearched = employees.filter((employee: Employee) => {
            if (employee.name.toLocaleLowerCase().indexOf(event.toLocaleLowerCase()) > -1) {
                return employee
            }
        })
        
        setEmployeeFound(dataSearched)

        if (inputSeekerRef.current?.value) {
            ulSeekerRef.current?.classList.add('active')
        }

        if (!inputSeekerRef.current?.value) {
            setEmployeeFound(employees)
        }
    }

    function hideUlItems(employee: Employee) {
        ulSeekerRef.current?.classList.remove('active')
        let columnIndx = 0
        columns.forEach(column => {
            column.childNodes.forEach((empCard: any) => {
                
                if (empCard.textContent == employee.name) {
                    columns[columnIndx].removeChild(empCard)
                    columns[columnIndx].prepend(empCard)
                    empCard.classList.add('searched')
                    setTimeout(() => {
                        empCard.classList.remove('searched')
                    }, 2000);
                }
            });
            columnIndx++
        });
    }

    return (
        <div className="category-search">
            <div className="text">
                <h4>Reporte 0</h4>
            </div>
            <div className="search-container">
                <input ref={inputSeekerRef} className="input-search" type="text" placeholder="Buscar empleado..." onChange={(event) => filterData(event.target.value)} />

                <div className="data-searched">
                    <ul ref={ulSeekerRef}>
                        {
                            inputSeekerRef.current?.value ? (
                                employeeFound.map((employee, index) =>
                                    <li className="category-item-searched" key={index} onClick={() => hideUlItems(employee)} >{employee['name']}</li>
                                )) : (<></>)
                        }
                        {
                            inputSeekerRef.current?.value && !employeeFound.length ? (
                                <li className="category-item-searched" >
                                        No existe ningún empleado con ese nombre!
                                    </li>
                            ) : (<></>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}