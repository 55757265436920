import { useHistory } from 'react-router';

// Context
import LandingPageContext from './LandingPageContext'

// Firestore
import firebase from '../../services/firebase/Config'
import { useState } from 'react';

const LandingPage: React.FC = (props: any) => {

    const firestore = firebase.firestore()
    const history = useHistory()

    const [imagesHome, setImagesHome] = useState([])

    const [promotions, setPromotions] = useState([])

    const [services, setServices] = useState([])

    function getImagesHome() {
        firestore.collection('ImagesHome').onSnapshot(response => {
            const docs: any = []
            response.forEach(imageHome => {
                docs.push({ ...imageHome.data(), id: imageHome.id })
            })
            setImagesHome(docs)
        })
    }

    function getServices() {
        firestore.collection('ServicesWS').onSnapshot(response => {
            const docs: any = []
            response.forEach(serviceWS => {
                docs.push({ ...serviceWS.data(), id: serviceWS.id })
            })
            setServices(docs)
        })
    }

    function getPromotions() {
        firestore.collection('Promotions').onSnapshot(response => {
            const docs: any = []
            response.forEach(serviceWS => {
                docs.push({ ...serviceWS.data(), id: serviceWS.id })
            })
            setPromotions(docs)
        })
    }

    function redirect(page: string) {
        document.querySelector('.navbar-home')!.scrollIntoView({ block: 'start', behavior: 'smooth' });

        if (history.location.pathname === page) {
            return document.querySelector('.navbar-home')!.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        setTimeout(() => {
            handleAddingStyles()
        }, 400);

        setTimeout(() => {
            goToPage(page)
        }, 1400);
    }

    function goToPage(page: string) {
        switch (page) {
            case '/':
                history.push('/')
                break;
            case '/about-us':
                history.push('/about-us')
                break;

            case '/services':
                history.push('/services')
                break;

            default:
                break;
        }
    }

    function handleAddingStyles() {
        const loaderContainer1 = document.querySelector('.loader-container-1')
        const loaderContainer2 = document.querySelector('.loader-container-2')
        const loaderContent = document.querySelector('.loader-content')

        if (loaderContainer1!.classList.contains('inactive')) {
            loaderContainer1!.classList.remove('inactive')
            loaderContainer2!.classList.remove('inactive')
            loaderContent!.classList.remove('inactive')
        }

        loaderContainer1!.classList.add('active')
        loaderContainer2!.classList.add('active')
    }

    function showNotification() {
        const notificationRef = document.querySelector('.notification')
        if (!notificationRef!.classList.contains('active')) {
            notificationRef!.classList.add('active')
        } else {
            notificationRef!.classList.remove('active')
        }

        setTimeout(() => {
            notificationRef!.classList.remove('active')
        }, 4000);
    }

    const value = {
        imagesHome, getImagesHome, promotions, services, getPromotions, getServices, handleAddingStyles, redirect, goToPage, showNotification
    }

    return (
        <LandingPageContext.Provider value={value}>
            {props.children}
        </LandingPageContext.Provider>
    )
}

export default LandingPage