import { useEffect, useState } from "react"
import { useHistory } from "react-router";

// Context

// Component and CSS
import SelectQuadrille from "../modal/selectQuadrille/SelectQuadrille";
import './Show.css'

// Interfaces


export default function ShowReport0() {

    const history = useHistory()

    const [idnx, setIdnx] = useState<number>(0)
    const [quadrilles, setQuadrilles] = useState([])

    const [selectQuadrilleModal, setSelectQuadrilleModal] = useState<boolean>(true)
    const [quadrilleSelected, setQuadrilleSelected] = useState([])

    function quadrille(quadrille: any, indx: number) {
        let aQuadrille: any = []
        setIdnx(indx)
        setQuadrilles(quadrille)

        for (let index = 0; index < Object.keys(quadrille).length; index++) {
            quadrille[index].employees.forEach((element: any) => {
                aQuadrille.push({
                    quadrille: quadrille[index].id,
                    employee: element,
                    service: quadrille[index].service
                })
            });
        }

        setQuadrilleSelected(aQuadrille)
    }

    function sendDataToDragEmps() {

        let data = Object.keys(quadrilles).length + ',' + Object.keys(quadrilles).length + ',' + 'edit' + ',' + idnx + ',' + 'false'
        localStorage.setItem('nextFormEdit', data)

        history.push('/admin/report-0')
    }

    return (
        <div className="grid-admin-page">
            <div className="blank-space" />
            <div className="show-quadrilles">
                <button className="btn-show-quadrille btn-show" onClick={() => setSelectQuadrilleModal(true)}> Ver otro </button>
                {
                    quadrilleSelected.length ? (
                        <button className="btn-show-quadrille btn-edit" onClick={() => sendDataToDragEmps()}> Editar </button>
                    ) : (<></>)
                }

                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Empleado</th>
                            <th>Servicio</th>
                            <th>Cuadrilla</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            quadrilleSelected.length ? (
                                quadrilleSelected.map((quadrille: any, index: number) =>

                                    <tr key={index}>
                                        <td key={index}>{index + 1}</td>
                                        <td>{quadrille['employee']}</td>
                                        <td>{quadrille['service']}</td>
                                        <td>{(quadrille['quadrille'])}</td>
                                    </tr>
                                )
                            ) : (
                                    <tr>
                                        <td>No ha seleccionado ninguna cuadrilla</td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>

            </div>

            <SelectQuadrille isOpen={selectQuadrilleModal} onClose={() => setSelectQuadrilleModal(!selectQuadrilleModal)} showQuadrille={quadrille} />
        </div>
    )
}