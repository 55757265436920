// Components and CSS

// Interfaces
interface ModalService {
    isOpen: boolean,
    data: any,
    onClose: () => void
}

export const ModalServices:React.FC<ModalService> = ({isOpen, data, onClose, children}) => {
    
    return isOpen ?  (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <button className="btn-close-modal" onClick={onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                    
                    <h5>Servicios Arpi Technology</h5>
                </div>
                <div className="modal-services-ws modal-body">
                    {
                        data ? (
                            <div>
                                <img className="service-image" src={data['urlImage']} alt="" />
                                <p>{data['title']}</p>
                                <p>{data['description']}</p>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    ) : null
}