import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

// Context
import ServicesContext from '../../../context/services/ServicesContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from '../../globals/alert/Alert'
import CreateServiceModal from './modal/create/Create'
import EditServiceModal from './modal/edit/Edit'
import ServiceSeeker from './seeker/Seeker'
import './Services.css'

// Interfaces
import { initialAlertDataValues } from '../../../interfaces/alert/Alert'

export default function Services() {

    const { services, serviceSelected, deleteService } = useContext(ServicesContext)

    // Open Create Modal
    const [createServiceModal, setCreateServiceModal] = useState<boolean>(false)

    // Open Edit Modal
    const [editServiceModal, setEditServiceModal] = useState({
        open: false,
        data: {}
    })

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function handleAddingStylesUl(index: number) {
        const ul = document.querySelectorAll('.service-options')

        if (ul[index]) {
            if (ul[index].classList.contains('active')) {
                ul[index].classList.remove('active')
            } else {
                ul[index].classList.add('active')
            }
        }
    }

    function deleteServices(service: any) {
        try {
            deleteService(service.id)
            toast('Servicio eliminado con éxito!', {
                type: 'success'
            })
        } catch (error) {
            toast('No se pudo eliminar el servicio!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page">
            <div className="blank-space" />

            <div className="services-admin">
                <ServiceSeeker />
                <button className="btn-add" onClick={() => setCreateServiceModal(!createServiceModal)}>
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg> Agregar
                </button>

                <div className="filter-services-container">
                    <div className="items">
                        <p className="active">Todo</p>
                        <p>En proceso</p>
                        <p>Terminado</p>
                    </div>
                </div>
                <div className="services-data">
                    {
                        !services.length && !Object.values(serviceSelected).length ? (<p>Cargando...</p>) : (<></>)
                    }
                    {
                        !serviceSelected.length ? (
                            services.map((service, index) =>

                                <div className="service-container" key={index}>
                                    <div className="tag" />
                                    <div className="line-elements">
                                        <p className="service-title">{service['name']}</p>
                                        <div className="btn-options">
                                            <button onClick={() => handleAddingStylesUl(index)} >
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
                                                </svg>
                                            </button>
                                            <ul className="service-options">
                                                <Link to={`/admin/services/${service['id']}`} key={index}>
                                                    <li>
                                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                                                        </svg> Ver
                                                    </li>
                                                </Link>
                                                <li onClick={() => setEditServiceModal({
                                                    open: !editServiceModal.open,
                                                    data: service
                                                })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg> Editar
                                                    </li>
                                                <li onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar servicio', text: ('¿Desea eliminar el servicio ' + service['name'] + '?'), data: service })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg> Eliminar
                                                    </li>
                                                <li>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M13 21C13 21.35 13.07 21.69 13.18 22H5C3.9 22 3 21.11 3 20V6C3 4.89 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.89 21 6V11.78C20.42 11.38 19.74 11.13 19 11.05V9H5V20H13V21M22 17V21C22 21.55 21.55 22 21 22H16C15.45 22 15 21.55 15 21V17C15 16.45 15.45 16 16 16V15.5C16 14.12 17.12 13 18.5 13S21 14.12 21 15.5V16C21.55 16 22 16.45 22 17M20 15.5C20 14.67 19.33 14 18.5 14S17 14.67 17 15.5V16H20V15.5Z" />
                                                    </svg> Terminar
                                                    </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p className="service-description">{service['description']}</p>
                                    <div className="extra-info">
                                        <p className="company">Solicitado: &nbsp; <strong>{service['company']}</strong></p>
                                        <p className="date">Creado: &nbsp; 17 / 02 / 2021</p>
                                    </div>
                                </div>
                            )
                        ) : (
                            serviceSelected.map((service, index) =>
                                <div className="service-container" key={index}>
                                    <div className="tag" />
                                    <div className="line-elements">
                                        <p className="service-title">{service['name']}</p>
                                        <div className="btn-options">
                                            <button onClick={() => handleAddingStylesUl(index)} >
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
                                                </svg>
                                            </button>
                                            <ul className="service-options">
                                                <Link to={`/admin/services/${service['id']}`} key={index}>
                                                    <li>
                                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                                                        </svg> Ver
                                                    </li>
                                                </Link>
                                                <li onClick={() => setEditServiceModal({
                                                    open: !editServiceModal.open,
                                                    data: service
                                                })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg> Editar
                                                </li>
                                                <li onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar servicio', text: ('¿Desea eliminar el servicio ' + service['name'] + '?'), data: service })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg> Eliminar
                                                </li>
                                                <li>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M13 21C13 21.35 13.07 21.69 13.18 22H5C3.9 22 3 21.11 3 20V6C3 4.89 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.89 21 6V11.78C20.42 11.38 19.74 11.13 19 11.05V9H5V20H13V21M22 17V21C22 21.55 21.55 22 21 22H16C15.45 22 15 21.55 15 21V17C15 16.45 15.45 16 16 16V15.5C16 14.12 17.12 13 18.5 13S21 14.12 21 15.5V16C21.55 16 22 16.45 22 17M20 15.5C20 14.67 19.33 14 18.5 14S17 14.67 17 15.5V16H20V15.5Z" />
                                                    </svg> Terminar
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p className="service-description">{service['description']}</p>
                                    <div className="extra-info">
                                        <p className="company">Solicitado: &nbsp; <strong>{service['company']}</strong></p>
                                        <p className="date">Creado: &nbsp; 17 / 02 / 2021</p>
                                    </div>
                                </div>
                            )
                        )
                    }

                </div>

            </div>

            <CreateServiceModal isOpen={createServiceModal} onClose={() => setCreateServiceModal(!createServiceModal)} />
            <EditServiceModal isOpen={editServiceModal} onClose={() => setEditServiceModal({ ...editServiceModal, open: !editServiceModal.open })} />
            {/* <CreateCategoryModal isOpen={createCategoryModal} onClose={() => setCreatCategoryModal(!createCategoryModal)} />
            <EditCategoryModal isOpen={editCategoryModal} onClose={() => setEditCategoryModal({ ...editCategoryModal, open: !editCategoryModal.open })} /> */}
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={() => deleteServices(alertOpen.data)} />
        </div>
    )
}