import { useContext, useRef, useState } from 'react'

// Context
import ProductsContext from '../../../../context/products/ProductsContext'

// Components and CSS
import './Seeker.css'

// Interfaces
import Product from '../../../../interfaces/product/Product'

export default function ProductSeeker() {

    const inputSeekerRef = useRef<HTMLInputElement>(null)
    const ulSeekerRef = useRef<HTMLUListElement>(null)

    const { products, showProductSelected } = useContext(ProductsContext)
    const [productFound, setProductFound] = useState([])

    function filterData(event: string) {
        
        let dataSearched = products.filter((product: Product) => {
            if (product.name.toLocaleLowerCase().indexOf(event.toLocaleLowerCase()) > -1 ||
            product.barcode.toLocaleLowerCase().indexOf(event.toLocaleLowerCase()) > -1) {
                return product
            }
        })
        setProductFound(dataSearched)

        if (inputSeekerRef.current?.value) {
            ulSeekerRef.current?.classList.add('active')
        }

        if (!inputSeekerRef.current?.value) {
            showProductSelected(products)
        }
    }

    function hideUlItems(product: Product) {
        showProductSelected(product)
        ulSeekerRef.current?.classList.remove('active')
    }

    return (
        <div className="product-search">
            <div className="text">
                <h4>Productos</h4>
            </div>

            <div className="search-container">
                <input ref={inputSeekerRef} className="input-search" type="text" placeholder="Buscar producto..." onChange={(event) => filterData(event.target.value)} />

                <div className="data-searched">
                    <ul ref={ulSeekerRef}>
                        {
                            inputSeekerRef.current?.value ? (
                                productFound.map((product, index) =>
                                    <li className="category-item-searched" key={index} onClick={() => hideUlItems(product)} >
                                        <img src={product['image']} alt="" />  {product['name']} &nbsp; - &nbsp; {product['SKU']}
                                    </li>
                                )) : (<></>)
                        }
                        {
                            inputSeekerRef.current?.value && !productFound.length ? (
                                <li className="category-item-searched" >
                                        No existe ningún producto con esos datos!
                                    </li>
                            ) : (<></>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}