// React
import { useContext } from 'react'
import { useHistory } from 'react-router'

// Libraries
import { toast } from 'react-toastify'

// Context
import AdminContext from '../../../context/admin/AdminContext'
import Report0Context from '../../../context/reports/report0/Report0Context'

// Components and CSS
import './Home.css'

export default function Home() {

    const { imagesHomeSize, promotionsSize, servicesSize } = useContext(AdminContext)
    const { reports } = useContext(Report0Context)

    const history = useHistory()
    const date = new Date()

    function checkReport () {
        if (reports) {
            history.push('/admin/quadrilles')
        } else {
            toast('Aún no se han asignado las cuadrillas', {
                type: 'warning'
            })
            history.push('/admin/report-0')
        }
    }

    return (
        <div className="grid-admin-page home">
            <div className="blank-space" />
            <div className="home-container">
                <div className="home-website">

                    <h4>Página Web</h4>

                    <div className="grid col-3 mid-col-2 small-col-1">
                        <div className="card" onClick={() => history.push('/admin/website-images-home')}>
                            <div className="column" />
                            <div className="data">
                                <div className="image-container">
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                    </svg>
                                </div>
                                <p>Inicio</p>
                                <div className="data-content display-flex">
                                    <p>Total de imágenes:</p>
                                    {
                                        imagesHomeSize ? (
                                            <p>{imagesHomeSize}</p>
                                        ) : (<p className="loading-data">Cargando...</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card" onClick={() => history.push('/admin/website-promotions')}>
                            <div className="column" />
                            <div className="data">
                                <div className="image-container">
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z" />
                                    </svg>
                                </div>
                                <p>Promociones</p>
                                <div className="data-content display-flex">
                                    <p>Total de promociones:</p>
                                    {
                                        promotionsSize ? (
                                            <p>{promotionsSize}</p>
                                        ) : (<p className="loading-data">Cargando...</p>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="column" />
                            <div className="data" onClick={() => history.push('/admin/website-services')}>
                                <div className="image-container">
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" />
                                    </svg>
                                </div>
                                <p>Servicios</p>
                                <div className="data-content display-flex">
                                    <p>Total de servicios:</p>
                                    {
                                        servicesSize ? (
                                            <p>{servicesSize}</p>
                                        ) : (<p className="loading-data">Cargando...</p>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-employees">
                    <h4>Empleados</h4>

                    <div className="grid col-3 mid-col-2 small-col-1">

                        <div className="card">
                            <div className="column" />
                            <div className="data" onClick={checkReport}>
                                <div className="image-container">
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L6.04,7.5L12,10.85L17.96,7.5L12,4.15Z" />
                                    </svg>
                                </div>
                                <p>Cuadrillas</p>
                                <div className="data-content display-flex">
                                    <p>Día:</p>
                                    {
                                        date ? (<p className="date">{date.getDate() + ' / ' + (date.getMonth() + 1) + ' / ' + date.getFullYear() }</p>) : (<p>Cargando...</p>)
                                    }
                                </div>
                                <div className="data-content display-flex">
                                    <p>Cuadrillas asignadas</p>
                                    {
                                        reports[0] ? (
                                            <p className="status-succeeded">{Object.values(reports[0]['quadrilles']).length}</p>
                                        ) : (
                                            <p className="status-unsucceeded">Ninguna</p>
                                        )
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="column" />
                            <div className="data">
                                <div className="image-container">
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L6.04,7.5L12,10.85L17.96,7.5L12,4.15Z" />
                                    </svg>
                                </div>
                                <p>Servicios</p>
                                <div className="data-content display-flex">
                                    <p>Total de servicios:</p>
                                    <p>500</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}