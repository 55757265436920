import { createContext } from "react";
import Vehicle from "../../interfaces/vehicle/Vehicle";
import VehicleData from "../../interfaces/vehicle/VehicleData";

const VehicleContext = createContext({
    vehicles: [],
    createVehicle: (vehicle: Vehicle) => {},
    editVehicle: (vehicle: VehicleData) => {},
    deleteVehicle: (vehicleID: string) => {}
})

export default VehicleContext