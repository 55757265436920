import React, { useEffect, useState } from 'react'
import 'firebase/storage'

// Firestore
import firebase from '../../services/firebase/Config'

// Context
import PromotionContext from './PromotionContext'

const firestore = firebase.firestore()
const storageRef = firebase.storage().ref('Website')

interface PromotionWS {
    id: string,
    title: string,
    mainDescription: string,
    extraInformation1: string,
    extraInformation2: string,
    initialDate: {
        day: string,
        month: string,
        year: number
    },
    endDate: {
        day: string,
        month: string,
        year: number
    },
    createdAt: number
}

const ImageHome: React.FC = (props: any) => {
    const [promotions, setPromotions] = useState([])

    useEffect(() => {
        return getPromotions()
    }, [])

    async function addPromotion(promotion: PromotionWS, image: string) {
        return await firestore.collection('Promotions').doc().set({
            title: promotion.title,
            mainDescription: promotion.mainDescription,
            extraInformation1: promotion.extraInformation1,
            extraInformation2: promotion.extraInformation2,
            initialDate: promotion.initialDate,
            endDate: promotion.endDate,
            createdAt: promotion.createdAt,
            urlImage: image
        })
    }

    function getPromotions () {
        firestore.collection('Promotions').onSnapshot(response => {
            const docs: any = []
            response.forEach(promotion => {
                let newDate = new Date(promotion.data().createdAt)
                let date = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear()
                docs.push({ ...promotion.data(), id: promotion.id, createdAt: date })
            })
            setPromotions(docs)
        })
    }

    async function editPromotion(promotion: PromotionWS, image: any) {
        return await firestore.collection('Promotions').doc(promotion.id).set({
            title: promotion.title,
            mainDescription: promotion.mainDescription,
            extraInformation1: promotion.extraInformation1,
            extraInformation2: promotion.extraInformation2,
            initialDate: promotion.initialDate,
            endDate: promotion.endDate,
            createdAt: promotion.createdAt,
            urlImage: image
        })
    }

    async function deletePromotion(promotionId: string) {
        return await firestore.collection('Promotions').doc(promotionId).delete()
    }

    async function storage(image: any) {
        await storageRef.child('/Promotions/' + image.name).put(image)
    }

    const value = {
        promotions, addPromotion, editPromotion, deletePromotion, storage
    }

    return (
        <PromotionContext.Provider value={value}>
            {props.children}
        </PromotionContext.Provider>
    )
}

export default ImageHome