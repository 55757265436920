import { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'

export default function RequireAuth({ Component, ...rest }: any) {
  const { user } = useContext(AuthContext)

  return (
    <Route {...rest} render={props => {
      return user ? <Component {...props} /> : <Redirect to="/admin/signin" />
    }}>

    </Route>
  )
}