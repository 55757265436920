import { useContext, useEffect, useState } from 'react'

// Context
import ProductsContext from '../../../context/products/ProductsContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from '../../globals/alert/Alert'
import AddProductsModal from './modal/add/Add'
import CreateProductsModal from './modal/create/Create'
import EditProductsModal from './modal/edit/Edit'
import ShowProductsModal from './modal/show/Show'
import ProductSeeker from './seeker/Seeker'
import './Products.css'

// Interfaces
import { initialAlertDataValues } from '../../../interfaces/alert/Alert'

export default function Products() {

    const { products, productSelected, deleteProducts } = useContext(ProductsContext)

    // Open Create Modal
    const [createProductModal, setCreatProductModal] = useState<boolean>(false)

    // Open Add Modal
    const [addProductModal, setAddProductModal] = useState({
        open: false,
        product: {}
    })

    // Open Show Modal
    const [showProductModal, setShowProductModal] = useState({
        open: false,
        product: {}
    })

    // Open Edit Modal
    const [editProductModal, setEditProductModal] = useState({
        open: false,
        product: {}
    })

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function deleteProduct(productId: any) {
        try {
            deleteProducts(productId.id)
            toast('Producto eliminado del inventario!', {
                type: 'success'
            })
        } catch (error) {
            toast('No se pudo eliminar el producto del inventario!', {
                type: 'error'
            })
        }
    }
    return (
        <div className="grid-admin-page">
            <div className="blank-space" />

            <div className="products">
                <ProductSeeker />
                <button className="btn-add" onClick={() => setCreatProductModal(!createProductModal)} >
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg> Agregar
                </button>

                {
                    !products.length && !Object.values(productSelected).length ? (<p>Cargando...</p>) : (<></>)
                }

                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th></th>
                            <th>Nombre</th>
                            <th>SKU</th>
                            <th>Stock</th>
                            <th>Estatus</th>
                            <th>Código de barras</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !productSelected.length ? (
                                products.map((product, index) =>
                                    <tr key={index}>
                                        <td data-label="ID">{(index + 1)}</td>
                                        <td data-label="Imágen" className="image">
                                            {
                                                product['image'] ? (<img src={product['image']} alt="" />) :
                                                    (<svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                                    </svg>)
                                            }

                                        </td>
                                        <td data-label="Nombre">{product['name']}</td>
                                        <td data-label="SKU">{product['SKU']}</td>
                                        <td data-label="Stock">{product['stock']}</td>
                                        <td data-label="Estatus">{product['status'] > 0 ?
                                            (<div className="there-are-products">
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                                </svg>
                                            </div>) :
                                            (<div className="there-are-not-products">
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                                </svg>
                                            </div>)}
                                        </td>
                                        <td data-label="Código de barras">{product['barcode']}</td>
                                        <td data-label="Acciones">
                                            <div className="btn-container">
                                                <button onClick={() => setAddProductModal({
                                                    open: !showProductModal.open,
                                                    product: product
                                                })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M21 7H3V3H21V7M13 19C13 19.7 13.13 20.37 13.35 21H4V8H20V13.09C19.67 13.04 19.34 13 19 13C15.69 13 13 15.69 13 19M15 13V11.5C15 11.22 14.78 11 14.5 11H9.5C9.22 11 9 11.22 9 11.5V13H15M20 18V15H18V18H15V20H18V23H20V20H23V18H20Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setShowProductModal({
                                                    open: !showProductModal.open,
                                                    product: product
                                                })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setEditProductModal({
                                                    open: !editProductModal.open,
                                                    product: product
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar imágen', text: ('¿Desea eliminar la imagen ' + product['name'] + '?'), data: product })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ) : (

                                productSelected.map((product, index) =>
                                    <tr key={index}>
                                        <td data-label="ID">{(index + 1)}</td>
                                        <td data-label="Imágen" className="image">
                                            {
                                                product['image'] ? (<img src={product['image']} alt="" />) :
                                                    (<svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                                    </svg>)
                                            }

                                        </td>
                                        <td data-label="Nombre">{product['name']}</td>
                                        <td data-label="Stock">{product['stock']}</td>
                                        <td data-label="Estatus">{product['stock'] > 0 ?
                                            (<div className="there-are-products">
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                                </svg>
                                            </div>) :
                                            (<div className="there-are-not-products">
                                                <svg className="svg-icon" viewBox="0 0 24 24">
                                                    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                                </svg>
                                            </div>)}
                                        </td>
                                        <td data-label="Código de barras">{product['barcode']}</td>
                                        <td data-label="Acciones">
                                            <div className="btn-container">
                                                <button onClick={() => setAddProductModal({
                                                    open: !showProductModal.open,
                                                    product: product
                                                })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M21 7H3V3H21V7M13 19C13 19.7 13.13 20.37 13.35 21H4V8H20V13.09C19.67 13.04 19.34 13 19 13C15.69 13 13 15.69 13 19M15 13V11.5C15 11.22 14.78 11 14.5 11H9.5C9.22 11 9 11.22 9 11.5V13H15M20 18V15H18V18H15V20H18V23H20V20H23V18H20Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setShowProductModal({
                                                    open: !showProductModal.open,
                                                    product: product
                                                })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setEditProductModal({
                                                    open: !editProductModal.open,
                                                    product: product
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar imágen', text: ('¿Desea eliminar la imagen ' + product['name'] + '?'), data: product })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>

            </div>
            <AddProductsModal isOpen={addProductModal} onClose={() => setAddProductModal({ ...addProductModal, open: !addProductModal.open })} />
            <CreateProductsModal isOpen={createProductModal} onClose={() => setCreatProductModal(!createProductModal)} />
            <ShowProductsModal isOpen={showProductModal} onClose={() => setShowProductModal({ ...showProductModal, open: !showProductModal.open })} />
            <EditProductsModal isOpen={editProductModal} onClose={() => setEditProductModal({ ...editProductModal, open: !editProductModal.open })} />
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={() => deleteProduct(alertOpen.data)} />
        </div>
    )
}