import { useContext, useEffect, useState } from 'react'

// Context
import CategoryContext from '../../../context/categories/CategoryContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import CreateCategoryModal from './modal/create/Create'
import EditCategoryModal from './modal/edit/Edit'
import CategorySeeker from './seeker/Seeker'
import { Alert } from '../../globals/alert/Alert'
import './Categories.css'

// Interfaces
import { initialAlertDataValues } from '../../../interfaces/alert/Alert'

export default function Categories() {

    const { categories, categorySelected, deleteCategory } = useContext(CategoryContext)

    // Open Create Modal
    const [createCategoryModal, setCreatCategoryModal] = useState<boolean>(false)

    // Open Edit Modal
    const [editCategoryModal, setEditCategoryModal] = useState({
        open: false,
        category: {}
    })

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function deleteCategories(product: any) {
        try {
            deleteCategory(product.id)
            toast('Categoria eliminada con éxito!', {
                type: 'success'
            })
        } catch (error) {
            toast('No se pudo eliminar la categoria!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page">
            <div className="blank-space" />

            <div className="categories">
                <CategorySeeker />
                <button className="btn-add" onClick={() => setCreatCategoryModal(!createCategoryModal)}>
                    <svg className="svg-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg> Agregar
                </button>

                {
                    !categories.length && !Object.values(categorySelected).length ? (<p>Cargando...</p>) : (<></>)
                }

                <table className="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !categorySelected.length ? (
                                categories.map((category, index) =>
                                    <tr key={index}>
                                        <td data-label="ID">{(index + 1)}</td>
                                        <td data-label="Nombre">{category['name']}</td>
                                        <td data-label="Acciones">
                                            <div className="btn-container">
                                                <button onClick={() => setEditCategoryModal({
                                                    open: !editCategoryModal.open,
                                                    category: category
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar categoría', text: ('¿Desea eliminar la categoria ' + category['name'] + '?'), data: category })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ) : (

                                categorySelected.map((category, index) =>
                                    <tr key={index}>
                                        <td data-label="ID">{(index + 1)}</td>
                                        <td data-label="Nombre">{category['name']}</td>
                                        <td data-label="Acciones">
                                            <div className="btn-container">
                                                <button onClick={() => setEditCategoryModal({
                                                    open: !editCategoryModal.open,
                                                    category: category
                                                })} >
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                    </svg>
                                                </button>
                                                <button onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar categoría', text: ('¿Desea eliminar la categoria ' + category['name'] + '?'), data: category })}>
                                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>

            </div>

            <CreateCategoryModal isOpen={createCategoryModal} onClose={() => setCreatCategoryModal(!createCategoryModal)} />
            <EditCategoryModal isOpen={editCategoryModal} onClose={() => setEditCategoryModal({ ...editCategoryModal, open: !editCategoryModal.open })} />
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={() => deleteCategories(alertOpen.data)} />
        </div>
    )
}