import { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"

// Context
import Report0Context from "../../../../../../context/reports/report0/Report0Context"

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import './SelectQuadrille.css'

// Interfaces
import Modal from "../../../../../../interfaces/reports/report0/modal/Modal"
import { initialAlertDataValues } from "../../../../../../interfaces/alert/Alert"
import { Alert } from "../../../../../globals/alert/Alert"


const SelectQuadrilles: React.FC<Modal> = (props) => {

    let reportsArry: any = []
    const { reports, deleteReport } = useContext(Report0Context)
    
    const [quadrilleSelected, setQuadrilleSelected] = useState([])
    const [index, setIndex] = useState<number>(0)

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    if (reports[0]) {
        reportsArry = reports[0]['quadrilles']
    }

    const history = useHistory()

    useEffect(() => {
        generateReports()
    }, [])

    function selectRadioBtn (index: number) {
        const radioBtns = document.querySelectorAll('.radio-buttons')
        
        radioBtns.forEach(radioBtn => {
            radioBtn.removeAttribute('checked')
        });

        radioBtns[index].setAttribute('checked', 'true')
        setQuadrilleSelected(reportsArry[index])
        setIndex(index)
    }

    function generateReports() {

        let html: any = []
        
        for (let index = 0; index < reportsArry.length; index++) {
            const createdDate = reportsArry[index][0].createdAt
            const date = new Date(createdDate)
            
            
            html.push(
                <div className="quadrilles-data" key={index} onClick={() => selectRadioBtn(index)} >
                    <div className="radio-button">
                        <div className="radio">
                            <input className={`radio-buttons radio-btn-${index}`} name="quadrille-selected" type="radio" value={index} />
                            <label>Cuadrilla {index + 1}</label>
                        </div>
                        <div className="extra-info">
                            <p>Creado el :{date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()} a las: {date.getHours() + ':' + date.getMinutes()} {date.getHours() >= 12 ? 'pm' : 'am'}</p>
                        </div>
                    </div>
                    <button className="remove-quadrille" onClick={() => setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Eliminar cuadrilla', text: ('¿Desea eliminar la cuadrilla ' + [index] + '?'), data: reports[index] })}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
            )
        }
        
        return html
    }

    function validation() {
        const radioBtns = document.querySelectorAll('.radio-buttons')
        let validate = false

        radioBtns.forEach(radioBtn => {
            if (radioBtn.getAttribute('checked')) {
                validate = true
            }
        });

        if (!validate) {
            return toast('Debe seleccionar un elemento para poder continuar', {
                type: 'warning'
            })
        }

        radioBtns.forEach(radioBtn => {
            radioBtn.removeAttribute('checked')
        });

        props.showQuadrille(quadrilleSelected, index)
        props.onClose()
    }

    function deleteQuadrille(index: number) {
        try {
            deleteReport(reports, index)
        } catch (error) {
            console.log(error);
            toast('No se pudo eliminar la cuadrilla', {
                type: 'success'
            })
        }
    }

    return props.isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Seleccione la cuadrilla que quiere ver</h5>

                    <button className="btn-close-modal" onClick={() => props.onClose()}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>

                <div className="select-quadrille-modal modal-body">
                    <button className="btn-add" onClick={()=> history.push('/admin/report-0')} >
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg> Crear
                    </button>
                    
                    {
                        reports[0] ? (
                            <form onSubmit={(event) => event.preventDefault()}>
                                {generateReports()}

                                <button type="submit" className="btn-submit" onClick={validation}>Ver</button>
                            </form>
                        ) : (<p>No hay reportes creados!</p>)
                    }
                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({ ...initialAlertDataValues, alertOpen: !alertOpen.alertOpen })} accept={() => deleteQuadrille(index)} />
        </div>
    ) : null
}

export default SelectQuadrilles;