import React, { useState } from 'react'
import 'firebase/storage'

// Context
import ImageHomeContext from './ImageHomeContext'

// Firestore
import firebase from '../../services/firebase/Config'
const firestore = firebase.firestore()
const storageRef = firebase.storage().ref('Website')

interface ImageHome {
    id: string,
    title: string,
    description: string
}

const ImageHome: React.FC = (props: any) => {
    const [imagesHome, setImagesHome] = useState([])

    async function addImage(imageHome: ImageHome, image: string) {
        return await firestore.collection('ImagesHome').doc().set({
            title: imageHome.title,
            description: imageHome.description,
            urlImage: image
        })
    }

    function getImages () {
        firestore.collection('ImagesHome').onSnapshot(response => {
            const docs: any = []
            response.forEach(imageHome => {
                docs.push({ ...imageHome.data(), id: imageHome.id })
            })
            setImagesHome(docs)
        })
    }

    async function editImage(imageHome: ImageHome, image: any) {
        return await firestore.collection('ImagesHome').doc(imageHome.id).set({
            title: imageHome.title,
            description: imageHome.description,
            urlImage: image
        })
    }

    async function deleteImage(imageId: string) {
        return await firestore.collection('ImagesHome').doc(imageId).delete()
    }

    async function storage(image: any) {
        await storageRef.child('/ImageHome/' + image.name).put(image)
    }

    const value = {
        imagesHome, getImages, addImage, editImage, deleteImage, storage
    }

    return (
        <ImageHomeContext.Provider value={value}>
            {props.children}
        </ImageHomeContext.Provider>
    )
}

export default ImageHome