import { useContext, useRef, useState } from "react";

// Context
import VehicleContext from "../../../../../context/vehicles/VehicleContext";

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from "../../../../globals/alert/Alert";
import './Create.css'

// Interfaces
import { initialAlertDataValues } from "../../../../../interfaces/alert/Alert";
import { initialValues } from "../../../../../interfaces/vehicle/Vehicle";
import Modal from "../../../../../interfaces/modal/Modal";

const CreateVehiclesModal: React.FC<Modal> = (props) => {
    const { createVehicle } = useContext(VehicleContext)

    const [vehicle, setVehicle] = useState(initialValues)
    const imageVehicleRef = useRef<HTMLInputElement>(null)
    const [vehicleSelectedBase64, setVehicleSelectedBase64] = useState('')

    const [alertOpen, setAlertOpen] = useState(initialAlertDataValues)

    function handleChangeInput(event: any) {
        const { name, value } = event.target
        setVehicle({ ...vehicle, [name]: value })
    }

    function selectImage(event: any) {
        if (event.target.files[0]) {
            // Load image selected in image input
            const reader = new FileReader();
            reader.onload = function (e) {
                // The file's text will be printed here
                setVehicleSelectedBase64(e.target?.result as string)
                setVehicle({...vehicle, image: e.target?.result as string})
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    function validations() {
        if (!vehicle.brand) {
            return toast('El campo marca no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!vehicle.model) {
            return toast('El campo modelo no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!vehicle.description) {
            return toast('El campo descripción no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!vehicle.observations) {
            return toast('El campo observations no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!vehicle.totalLts) {
            return toast('El campo cantidad total de litros por no puede estar vacio', {
                type: 'warning'
            })
        }
        if (!vehicle.laborArea) {
            return toast('El campo área designada no puede estar vacio', {
                type: 'warning'
            })
        }

        setAlertOpen({ ...alertOpen, alertOpen: true, title: 'Crear vehiculo', text: ('¿Desea crear el vehiculo ' + vehicle.brand.toLocaleLowerCase() + '?') })
    }

    async function handleSubmit () {
        try {
            createVehicle(vehicle)
            toast('Vehículo agregado con éxito!', {
                type: 'success'
            })
            setVehicle(initialValues)
            props.onClose()
        } catch (error) {
            return toast('Hubo algún problema y no se pudo agregar el vehículo!', {
                type: 'error'
            })
        }
    }

    return props.isOpen ? (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5>Agregar vehículo</h5>

                    <button className="btn-close-modal" onClick={props.onClose}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="brand"
                                required
                                value={vehicle?.brand}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleChangeInput(event)}
                            />
                            <span>Marca</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="model"
                                required
                                value={vehicle?.model}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleChangeInput(event)}
                            />
                            <span>Modelo</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="description"
                                required
                                value={vehicle?.description}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleChangeInput(event)}
                            />
                            <span>Descripción</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="text"
                                name="observations"
                                required
                                value={vehicle?.observations}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleChangeInput(event)}
                            />
                            <span>Observaciones</span>
                        </div>
                        <div className="input-container">
                            <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                            </svg>
                            <input
                                type="number"
                                name="totalLts"
                                required
                                value={vehicle?.totalLts}
                                autoComplete="off"
                                autoSave="off"
                                autoFocus={true}
                                onChange={(event) => handleChangeInput(event)}
                            />
                            <span>Cantidad de litros del vehículo</span>
                        </div>
                        <div className="input-container">
                            <select className="select" name="laborArea" value={vehicle.laborArea} onChange={(event) => handleChangeInput(event)} >
                                <option disabled value={''}>Seleccione un área laboral</option>
                                <option value="administración">Administración</option>
                                <option value="campo">Campo</option>
                            </select>
                        </div>
                        <div className="image-selected-container display-flex">
                            <div>
                                <input ref={imageVehicleRef} accept="image/*" type="file" hidden onChange={(event) => selectImage(event)} />
                                <button type="button" className="btn-image" onClick={() => imageVehicleRef.current?.click()}>
                                    <svg className="svg-icon" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,3A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H14.09C14.03,20.67 14,20.34 14,20C14,19.32 14.12,18.64 14.35,18H5L8.5,13.5L11,16.5L14.5,12L16.73,14.97C17.7,14.34 18.84,14 20,14C20.34,14 20.67,14.03 21,14.09V5C21,3.89 20.1,3 19,3H5M19,16V19H16V21H19V24H21V21H24V19H21V16H19Z" />
                                    </svg>
                                </button>
                                {
                                    vehicleSelectedBase64 ? (
                                        <button type="button" className="btn-clear-image" onClick={() => setVehicleSelectedBase64('')}>
                                            <svg className="svg-icon" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                                            </svg>
                                        </button>
                                    ) : (<></>)
                                }
                            </div>
                            <div className="image-selected">
                                <p>Imágen del vehículo:</p>
                                {
                                    vehicleSelectedBase64 ? (
                                        <img src={vehicleSelectedBase64} alt="" />
                                    ) : (<p className="no-image">Ninguno seleccionado.</p>)
                                }
                            </div>
                        </div>

                        <button className="btn-submit" type="submit" onClick={validations}>
                            Crear
                        </button>
                    </form>
                </div>
            </div>
            <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertDataValues, alertOpen: !alertOpen.alertOpen})} accept={handleSubmit} />
        </div>
    ) : null
}

export default CreateVehiclesModal