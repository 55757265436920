import { useContext, useEffect, useState } from "react";

// Context
import Report0Context from "../../../../context/reports/report0/Report0Context";

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import DragEmployees from "./dragEmployees.tsx/DragEmployees";
import Report0Seeker from "./seeker/Seeker";
import './Report0.css'

// Interfaces
import { initialValues } from "../../../../interfaces/reports/report0/Report0";
import Form from "./form/Form";

export default function Report0() {
    
    return (
        <div className="grid-admin-page">
            <div className="blank-space" />

            <div className="report-0">
                <Form />
            </div>
        </div>
    )
}