// React
import { useContext, useEffect, useState } from 'react'
import ImageHomeContext from '../../../../context/imageHome/ImageHomeContext'

// Libraries
import { toast } from 'react-toastify'

// Components and CSS
import { Alert } from '../../../globals/alert/Alert'
import { CreateImageHomeModal } from './modal/Create/Create'
import { EditImageHomeModal } from './modal/Edit/Edit'
import './ImagesCarousel.css'

export default function ImagesHomeCarousel () {
    const initialValuesEditModal = {
        isOpen: false,
        imageHomeData: {},
        image: {}
    }
    const initialAlertValues = {
        alertOpen: false,
        text: '',
        title: '',
        image: true,
        imageHomeValue: {}
    }
    const { imagesHome, getImages, deleteImage } = useContext(ImageHomeContext)

    const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
    const [isOpenEditModal, setOpenEditModal] = useState(initialValuesEditModal)

    // Alert Props
    const [alertOpen, setAlertOpen] = useState(initialAlertValues)

    useEffect(() => {
        if (!imagesHome.length) {
            return getImages()
        }
    }, [imagesHome])

    function removeImageHome(imageHome: any) {
        console.log('delete');
        
        try {
            deleteImage(imageHome['id'])
            toast('Imágen eliminada de la página web!', {
                type: 'success'
            })
        } catch (error) {
            toast('No se pudo eliminar la imágen de la página web!', {
                type: 'error'
            })
        }
    }

    return (
        <div className="grid-admin-page images-home">
            <div className="blank-space" />
            <div className="images-home-container">
                
                <div className="images-home-header">
                    <h4>Imágenes de inicio de Arpi Technology</h4>
                    <button className="btn-add" onClick={() => setOpenCreateModal(!isOpenCreateModal)}>
                        <svg className="svg-icon" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                        </svg> Agregar
                    </button>
                </div>

                <div className="image-home-body">
                    <div className="cards-container grid col-3 mid-col-2 small-col-1">
                        {
                            imagesHome ? (
                                imagesHome.map( (imageHome, index) => (
                                    <div className="card" key={index}>
                                        <div className="image-container">
                                            <img src={imageHome['urlImage']} alt="ImageHome" />
                                        </div>
                                        <div className="info">
                                            <p>{imageHome['title']}</p>
                                            <p>{imageHome['description']}</p>
                                            <div className="display-flex">
                                                <p>24-01-2021</p>
                                                <div className="btn-actions">
                                                    <button className="btn-edit" onClick={() => setOpenEditModal({ ...initialValuesEditModal, isOpen: !isOpenEditModal.isOpen, imageHomeData: imageHome})}>
                                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                                                        </svg>
                                                    </button>
                                                    <button className="btn-delete" onClick={() => setAlertOpen({...alertOpen, alertOpen: true, title: 'Eliminar imágen', text: ('¿Desea eliminar la imagen ' + imageHome['title']  + '?'), imageHomeValue: imageHome})}>
                                                        <svg className="svg-icon" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )  )
                            ) :(<div>Nel</div>)
                        }
                    </div>
                </div>
                <CreateImageHomeModal isOpen={isOpenCreateModal} onClose={() => setOpenCreateModal(!isOpenCreateModal)} />
                <EditImageHomeModal isOpen={isOpenEditModal.isOpen} data={isOpenEditModal.imageHomeData} onClose={() => setOpenEditModal({ ...initialValuesEditModal, isOpen: !isOpenEditModal.isOpen})} />
                <Alert alertIsOpen={alertOpen.alertOpen} data={alertOpen} onClose={() => setAlertOpen({...initialAlertValues, alertOpen: !alertOpen.alertOpen})} accept={() => removeImageHome(alertOpen.imageHomeValue)} />
            </div>
        </div>
    )
}

{/* <div className="info-text">
                                <p>Imágen 1</p>
                                <p>Detalles detallésticos... yeah!, Detalles detallésticos... yeah!, Detalles detallésticos... yeah!Detalles detallésticos... yeah!Detalles detallésticos... yeah!</p>
                                <p>24-01-2021</p>
                            </div> */}